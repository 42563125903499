import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

import Navigation from '../components/navigation'
import PageLoad from '../components/page_load'
import Help from '../components/help'

const Withdraw = () => {
	
	const [pageLoad, setPageLoad] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | WITHDRAW'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})
	}, [])

	return (
		<>
			{ pageLoad ? (
				<PageLoad />
			) : (
				<>
					<Navigation />

					<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
						<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">

							<p className="mt-20 lg:mt-0 text-3xl text-slate-300">Withdraw</p>
							<p className="mt-6 text-lg font-light text-slate-400">You don't have enough L15 balance to withdraw.</p>

						</div>
					</div>
				</>
			)}

			<Help />
		</>
	)
}

export default Withdraw;