
const Loader = () => {
	return (
		<>
			{/*<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen">
		        <img src="img/logo/laser_sq_logo.png" className="animate-bounce w-20 h-20" />
		    </div>*/}

			<button className="py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500/75">
				<div className="flex flex-col items-center">
	                <div className="w-5 h-5 rounded-full animate-spin border-2 border-solid border-slate-300 border-t-transparent"></div>
	            </div>
            </button>
		</>
	)
}

export default Loader;