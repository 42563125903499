import { React, useEffect, useState } from 'react'
import { LineChart, Line, YAxis } from 'recharts';

import { API_HOST } from '../../config_vars'

const L15 = () => {
	const [l15, setL15] = useState('')
	const [change, setChange] = useState('')
	const [graph, setGraph] = useState('')

	useEffect(() => {
		fetch(API_HOST + '/l15/nav')
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setL15(data.data)
					setChange(data.change_24h)
				} else {
					setL15('')
				}
			},
			(error) => {
				setL15('')
			}
		)

		fetch(API_HOST + '/l15/nav-all')
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setGraph(data.data)
				} else {
					setGraph('')
				}
			},
			(error) => {
				setGraph('')
			}
		)
	}, [])

	return (
		<div className="px-6 mt-8">
			<div className="p-6 w-full bg-neutral-900 rounded-xl">
				<div className="flex items-center space-x-2">
					<div className="w-10 h-10 bg-red-500 rounded-xl"></div>
					<div className="flex flex-col">
						<p className="text-md font-medium text-slate-300">L15 Index Token</p>
						<p className="text-xs font-light text-slate-400">Top 15 cryptocurrency benchmark</p>
					</div>
				</div>
				<div className="mt-6">
					<p className="text-xs font-light text-slate-400">CURRENT PRICE</p>

					{l15 ? (
						<p className="mt-1 text-xl font-medium text-green-500">{(l15.nav).toFixed(3)} <span className="text-xs font-light text-slate-400">USD (as on {l15.dt.date}, {l15.dt.time})</span></p>
					) : (
						<div className="animate-pulse mt-2 w-3/4 h-6 bg-neutral-800 rounded-lg"></div>
					)}

					{change ? (
						<p className="mt-4 text-xs font-light text-slate-400">CHANGE(24H): <span className="text-yellow-500">{(change).toFixed(2)}%</span></p>
					) : (
						<div className="animate-pulse mt-2 w-1/2 h-6 bg-neutral-800 rounded-lg"></div>
					)}

					{/*<p className="mt-4 text-xs font-light text-slate-400">CHANGE: <span className="text-green-400">+3.4%</span></p>*/}
				</div>

				<div className="mt-6 flex flex-col items-center">
					{ graph ? (
						<LineChart 
							width={300} 
							height={100} 
							data={graph} 
							margin={{top: 5, right: 10, left: 10, bottom: 5}}
							>
							<Line dataKey="nav" stroke="#f1f5f9" dot={false} />
							
          					<YAxis hide="false" type="number" domain={[graph[0]['nav'], 'dataMax']} />
						</LineChart>
					) : (
						<div className="animate-pulse w-full h-36 bg-neutral-800 rounded-xl">
						</div>
					) 
						
					}
				</div>

				{/*<div className="w-full h-40">
      				<Chart data={data} axes={axes} />
    			</div>*/}

			</div>
		</div>
	)
}

export default L15;