import { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from "react-router-dom";

import { auth } from '../firebase-config';
import { API_HOST } from '../config_vars';

// Components
import Navigation from '../components/navigation'
import PageLoad from '../components/page_load'

const Help = () => {

	const [pageLoad, setPageLoad] = useState(true)
	const [accActive, setAccActive] = useState(false)
	const [tab, setTab] = useState('actions')
	const [err, setErr] = useState('')
	const [loading, setLoading] = useState(false)
	const [submitMsg, setSubmitMsg] = useState(false)

	const [phone, setPhone] = useState('')
	const [category, setCategory] = useState('')
	const [msg, setMsg] = useState('')


	useEffect(() => {
		document.title = 'LASER.MONEY | HELP & SUPPORT'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})

	}, [])

	const click_handler = () => {
		if(accActive === true) {
			setAccActive(false)
		} else {
			setAccActive(true)
		}
	}

	const tabHandler = (tab) => {
		// console.log(ttype)
		setTab(tab)
	}

	const new_request = () => {

		setErr('')
		setLoading(true)

		fetch(API_HOST + '/support/new', {
			method: 'POST',
			body: JSON.stringify({
				phone: phone,
				category: category,
				msg: msg
			}),
			headers: {
				'Accept': 'application/json',
      			'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('accessToken')
			}
		})
		.then(res => res.json())
		.then(
		    (result) => {
		    	console.log(result)

		    	if(result.code === 200) {
					setLoading(false)
					setSubmitMsg(true)
		    	} else {
					setLoading(false)
					setErr('Error verifying PAN.')
		    	}
		    	
		    },
		    (error) => {
				console.log(error)
				setLoading(false)
				setErr('Error verifying PAN.')
		    }
		)

	}


	return (
		<>
			{ pageLoad ? (
				<PageLoad />
			) : (
				<div className="m-6 xl:m-10">
					<Navigation path='transactions' />

					<div className="my-20 flex flex-col items-center justify-center mx-auto lg:mt-20">
						<div className="md:bg-neutral-900 w-full lg:w-1/3 2xl:w-1/4 px-4 lg:px-10 lg:py-10 md:rounded-lg">

							<div className="flex items-center space-x-4">
								<div className="p-1.5 bg-slate-300 rounded-lg">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-7 h-7 text-neutral-900">
										<path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
									</svg>
								</div>
								<p className="mr-2 text-2xl font-semibold text-slate-300">Help / Support</p>
							</div>

							<div className="mt-10 flex flex-col items-center">
								<div className="w-full flex items-center bg-neutral-800 rounded-xl">
									{ tab === 'actions' ? (
										<div className="py-3 w-1/2 flex items-center justify-center space-x-2 bg-gradient-to-r from-red-500 to-yellow-500 rounded-lg">	
											<p className="text-sm font-medium">Quick Actions</p>
										</div>
									) : (
										<div onClick={() => tabHandler('actions')} className="cursor-pointer w-1/2 flex items-center justify-center space-x-2">
											<p className="text-sm font-light text-slate-300">Quick Actions</p>
										</div>
									) }

									{ tab === 'request' ? (
										<div className="py-3 w-1/2 flex items-center justify-center space-x-2 bg-gradient-to-r from-red-500 to-yellow-500 rounded-lg">	
											<p className="text-sm font-medium">Open Request</p>
										</div>
									) : (
										<div onClick={() => tabHandler('request')} className="cursor-pointer w-1/2 flex items-center justify-center space-x-2">
											<p className="text-sm font-light text-slate-300">Open Request</p>
										</div>
									) }
									
								</div>
							</div>

							{ tab === 'actions' &&
								<div className="mt-10 space-y-6">

									{ accActive ? (
										<div className="p-5 w-full bg-neutral-800 rounded-xl">
											<div onClick={click_handler} className="cursor-pointer flex items-center">
												<p className="text-lg text-slate-300">INR Deposit Account</p>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
						  							<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
												</svg>
											</div>

											<div className="mt-8 grid grid-cols-2 gap-2 text-xs">
												<p className="font-medium text-slate-300">Account Name</p>
												<p className="font-bold text-slate-300">Abhibha Technologies Private Limited <svg onClick={() => navigator.clipboard.writeText('Abhibha Technologies Private Limited')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
			  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
													</svg></p>

												<p className="font-medium text-slate-300">Account Number</p>
												<p className="font-bold text-slate-300">50200070275321 <svg onClick={() => navigator.clipboard.writeText('50200070275321')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
		  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
												</svg></p>
													
												
												<p className="font-medium text-slate-300">IFSC</p>
												<p className="font-bold text-slate-300">HDFC0000004 <svg onClick={() => navigator.clipboard.writeText('HDFC0000004')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
			  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
													</svg></p>

												<p className="font-medium text-slate-300">Account Type</p>
												<p className="font-bold text-slate-300">Current <svg onClick={() => navigator.clipboard.writeText('Current')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
			  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
													</svg></p>

											</div>
										</div>
									) : (
										<button onClick={click_handler} className="p-5 w-full bg-neutral-800 rounded-xl">
											<div className="cursor-pointer flex items-center">
												<p className="text-lg text-slate-300">INR Deposit Account</p>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							  						<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
												</svg>
											</div>
										</button>
									)}

									<div className="w-full p-4 bg-neutral-800 rounded-xl">
										<p className="text-lg text-slate-300">Write to us</p>
										<p className="mt-4 text-lg font-medium text-slate-100">team@laser.money</p>
									</div>

									<div onClick={() => window.open('//docs.laser.money')} className="flex items-center w-full p-4 bg-neutral-800 rounded-xl">
										<p className="text-lg text-slate-100">Check Docs</p>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
											<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
										</svg>
									</div>

									<div onClick={() => window.open('//docs.laser.money/legal')} className="flex items-center w-full p-4 bg-neutral-800 rounded-xl">
										<p className="text-lg text-slate-100">Legal</p>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
											<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
										</svg>
									</div>
								</div>

							}

							{ tab === 'request' &&

								<>

								{ submitMsg ? (

									<p className="mt-10 mb-28 lg:mb-20 text-md text-slate-100">Request submitted successfully. We will get back to you within 24 hours.</p>

								) : (
									<>
									<div className="mt-10 mb-28 lg:mb-20">

										<p className="text-sm font-light text-slate-300">CATEGORY</p>
										<select onChange={(e) => setCategory(e.target.value)} className="mt-2 p-3 w-full bg-neutral-800 border-0 rounded-lg text-slate-100">
											<option value="Payment" className="text-sm">Payment</option>
											<option value="Withdrawal" className="text-sm">Withdrawal</option>
											<option value="Other" className="text-sm">Other</option>
										</select>

										<p className="mt-8 text-sm font-light text-slate-300">PHONE</p>
										<input inputMode="numeric" pattern="[0-9]*" value={phone} onChange={(e) => setPhone(e.target.value)} className="mt-2 p-3 w-full bg-neutral-800 border-0 rounded-lg text-lg text-slate-100" maxLength="10" placeholder="Enter phone number" />
										
										<p className="mt-8 text-sm font-light text-slate-300">MESSAGE</p>
										<textarea onChange={(e) => setMsg(e.target.value)} class="mt-2 resize p-3 w-full bg-neutral-800 border-0 text-sm text-slate-100 rounded-lg"></textarea>
									</div>

									<div className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
										{err && <p className="mb-4 text-sm text-orange-400">{err} Try again or <Link to="https://docs.laser.money/reach-us" target="_blank" className="text-red-500">contact us.</Link></p>}

										{ loading ? (
											<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
												<div className="flex items-center space-x-4">
													<div className="flex flex-col items-center">
						                				<div className="w-6 h-6 rounded-full animate-spin border-2 border-solid border-slate-100 border-t-transparent"></div>
						            				</div>
													<p className="text-lg text-slate-100">SUBMITING</p>
												</div>
											</button>
										) : (
											<button onClick={new_request} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
												<p className="text-lg text-slate-100">SUBMIT</p>
											</button>
										)}

										
									</div>
									
									</>
								) }

								</>
								
							}
							
							{/* <div className="lg:hidden p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
								<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
									<p className="text-lg text-slate-100">NEW SUPPORT REQUEST</p>
								</button>
							</div>

							<div className="hidden lg:block mt-10">
								<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
									<p className="text-lg text-slate-100">NEW SUPPORT REQUEST</p>
								</button>
							</div> */}
							
						</div>
				    </div>

				    

				</div>
			)}
		</>
	)
}

export default Help;