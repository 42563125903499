import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

import { auth } from '../firebase-config';


import '../index.css';

const API_HOST = 'https://asia-east1-symply-app.cloudfunctions.net/laserApi';

const LandingNew = () => {

	const [l15, setL15] = useState('')
	const [change, setChange] = useState('')
	const [index, setIndex] = useState('')
	const [user, setUser] = useState('')
	const [graph, setGraph] = useState('')

	const chartData = [{'month':'January','l15':0.0,'nifty':0.0,'snp':0.0,'gold':0.0},{'month':'February','l15':39.0,'nifty':-1.77,'snp':7.72,'gold':5.05},{'month':'March','l15':39.0,'nifty':-4.26,'snp':3.33,'gold':1.87},{'month':'April','l15':65.0,'nifty':-3.88,'snp':7.85,'gold':7.9},{'month':'May','l15':69.0,'nifty':0.09,'snp':8.99,'gold':9.34}]

	useEffect(() => {

		document.title = "LASER MONEY | INDIA'S PREMIER CRYPTOCURRENCY INDEX TOKEN"

		onAuthStateChanged(auth, (user) => {
			if(user && user.emailVerified === true) {
				setUser(user)
			} else {
				setUser('')
			}
		})

		fetch(API_HOST + '/l15/nav')
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setL15(data.data)
					setChange(data.change_24h)
				} else {
					setL15('')
				}
			},
			(error) => {
				setL15('')
			}
		)

		fetch(API_HOST + '/l15/index')
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setIndex(data.data)
				} else {
					setIndex('')
				}
			},
			(error) => {
				setIndex('')
			}
		)

		fetch(API_HOST + '/l15/nav-all')
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setGraph(data.data)
				} else {
					setGraph('')
				}
			},
			(error) => {
				setGraph('')
			}
		)



	}, [])

	return (

		<>

			{/* NAV Bar */}

			<div className="lg:m-10 flex items-center lg:bg-neutral-900 rounded-xl">

				<div className="px-10 py-3 flex items-center lg:bg-neutral-800/50 rounded-xl space-x-4">
					<img alt="Laser Money Logo" src="img/logo/laser_sq_logo.png" className="hidden lg:block w-6 h-6" />
					<h1 className="hidden lg:block text-2xl font-semibold text-red-500">LASER.MONEY</h1>
				</div>

				<div className="ml-10 flex items-center space-x-10">
					<Link to="//docs.laser.money" target="_blank" rel="noopener noreferrer" className="hidden lg:block"><p className="text-lg font-medium text-slate-300">Docs</p></Link>
					<Link to="//docs.laser.money/legal" target="_blank" rel="noopener noreferrer" className="hidden lg:block"><p className="text-lg font-medium text-slate-300">Legal</p></Link>
					<Link to="//docs.laser.money/reach-us" target="_blank" rel="noopener noreferrer" className="hidden lg:block"><p className="text-lg font-medium text-slate-300">Contact</p></Link>
				</div>

				{user ? (
					<Link to="/home" className="hidden lg:block ml-auto"><button className="mr-6 px-5 py-1.5 bg-red-500 rounded-lg text-md font-medium text-slate-100">Dashboard</button></Link>
				) : (
					<Link to="/app" className="hidden lg:block ml-auto"><button className="mr-6 px-5 py-1.5 bg-red-500 rounded-lg text-md font-medium text-slate-100">Invest Now</button></Link>
				)}

			</div>

			<div className="mx-10 mt-10 flex items-center space-x-4">
				<img alt="Laser Money Logo" src="img/logo/laser_sq_logo.png" className="lg:hidden w-8 h-8" />
				<p className="lg:hidden text-3xl font-semibold text-red-500">LASER.MONEY</p>
			</div>

			{/* Section 1 - Header */}

			<div className="mx-6 mt-10 mb-20 lg:mx-28 2xl:mx-48 grid grid-cols-1 lg:grid-cols-2 gap-10">

				<div className="self-center">
					{/*<p className="text-6xl font-bold text-slate-300">Instantly diversify your crypto portfolio</p>*/}
					<p className="text-4xl lg:text-6xl font-bold text-slate-300">India's Premier</p>
					<p className="my-2 text-4xl lg:text-6xl font-bold text-slate-300">Cryptocurrency</p>
					<p className="mt-4 text-4xl lg:text-6xl font-bold text-slate-300">Index Token</p>

					{/*<p className="mt-6 text-2xl font-medium text-slate-400">Buy the L15 Index Token and you're set!</p>*/}
					<p className="mt-6 text-md lg:text-xl font-light text-slate-400"><span className="font-bold">L15 Index Token</span> - Get exposure to virtually the entire crypto ecosystem.</p>

				</div>

				<div className="flex flex-col items-end self-center justify-self-center">
					
					<div className="w-full p-8 bg-neutral-900 rounded-xl shadow-lg shadow-red-500/75">
						<div className="flex items-center space-x-2">
							<div className="w-11 h-11 bg-red-500 rounded-xl"></div>
							<div className="flex flex-col">
								<p className="text-sm lg:text-lg font-medium text-slate-300">L15 Index Token</p>
								<p className="text-xs lg:text-sm font-light text-slate-400">Laser Top 15 cryptocurrency benchmark tracker</p>
							</div>
						</div>

						<div className="mt-8">
							<p className="text-sm font-light text-slate-400">CURRENT PRICE</p>

							{l15 ? (
								<p className="mt-1 text-2xl font-medium text-green-500">{(l15.nav).toFixed(3)} <span className="text-sm font-light text-slate-400">USD (as on {l15.dt.date}, {l15.dt.time})</span></p>
							) : (
								<div className="animate-pulse mt-2 w-3/4 h-6 bg-neutral-800 rounded-lg"></div>
							)}

							{change ? (
								<p className="mt-4 text-sm font-light text-slate-400">CHANGE(24H): <span className="text-yellow-500">{(change).toFixed(2)}%</span></p>
							) : (
								<div className="animate-pulse mt-2 w-1/2 h-6 bg-neutral-800 rounded-lg"></div>
							)}
						</div>

						<div className="mt-6 flex flex-col items-center">
							{ graph && 
								<LineChart 
									width={300} 
									height={100} 
									data={graph} 
									margin={{top: 5, right: 10, left: 10, bottom: 5}}
									>
									<Line dataKey="nav" stroke="#f1f5f9" dot={false} />
									
		          					<YAxis hide="false" type="number" domain={[graph[0]['nav'], 'dataMax']} />
								</LineChart>
							}
						</div>

						<div className="mt-8 flex items-center">
							<Link to="/app" className="flex flex-col items-center w-1/2 py-2 bg-red-500 rounded-lg text-sm font-medium text-white">
								<button className="">INVEST NOW</button>
							</Link>
						</div>

					</div>
				</div> 

			</div>

			<div className="hidden m-10 lg:m-48 flex items-center">
				<div className="w-full lg:w-1/2">
					{/*<p className="text-6xl font-bold text-slate-300">Instantly diversify your crypto portfolio</p>*/}
					<p className="text-6xl font-bold text-slate-300">India's Premier</p>
					<p className="my-2 text-6xl font-bold text-slate-300">Cryptocurrency</p>
					<p className="mt-4 text-6xl font-bold text-slate-300">Index Token</p>

					{/*<p className="mt-6 text-2xl font-medium text-slate-400">Buy the L15 Index Token and you're set!</p>*/}
					<p className="mt-6 text-xl font-light text-slate-400"><span className="font-bold">L15 Index Token</span> - Get exposure to virtually the entire crypto ecosystem.</p>

				</div>

				<div className="w-full lg:w-1/2 flex flex-col items-end">
					
					<div className="lg:w-4/5 2xl:w-3/5 p-8 bg-neutral-900 rounded-xl shadow-lg shadow-red-500/75">
						<div className="flex items-center space-x-2">
							<div className="w-11 h-11 bg-red-500 rounded-xl"></div>
							<div className="flex flex-col">
								<p className="text-lg font-medium text-slate-300">L15 Index Token</p>
								<p className="text-sm font-light text-slate-400">Laser Top 15 cryptocurrency benchmark tracker</p>
							</div>
						</div>

						<div className="mt-8">
							<p className="text-sm font-light text-slate-400">CURRENT PRICE</p>

							{l15 ? (
								<p className="mt-1 text-2xl font-medium text-green-500">{(l15.nav).toFixed(3)} <span className="text-sm font-light text-slate-400">USD (as on {l15.dt.date}, {l15.dt.time})</span></p>
							) : (
								<div className="animate-pulse mt-2 w-3/4 h-6 bg-neutral-800 rounded-lg"></div>
							)}

							{change ? (
								<p className="mt-4 text-sm font-light text-slate-400">CHANGE(24H): <span className="text-yellow-500">{(change).toFixed(2)}%</span></p>
							) : (
								<div className="animate-pulse mt-2 w-1/2 h-6 bg-neutral-800 rounded-lg"></div>
							)}
						</div>

						<div className="mt-8 flex items-center">
							<Link to="/app" className="flex flex-col items-center w-1/2 py-2 bg-red-500 rounded-lg text-sm font-medium text-white">
								<button className="">INVEST NOW</button>
							</Link>
						</div>

					</div>
				</div> 

			</div>

			{/* Quote */}

			<div className="mx-6 lg:mx-20 2xl:mx-28 my-20 px-4 py-8 bg-red-500 rounded-2xl">
				<p className="text-sm lg:text-lg text-white text-center italic"><span className="">“Don't look for the needle in the haystack. Just buy the haystack!”</span> ― John C. Bogl</p>
			</div>

			{/* Section 2 - What is L15 */}

			<div className="mx-6 lg:mx-20 2xl:mx-28 mt-20 px-4 py-10 lg:p-10 bg-neutral-950 rounded-3xl">

				<div className="flex flex-col items-center space-y-4">
					<p className="text-2xl lg:text-4xl font-medium text-slate-300 text-center">What is the L15 Index Token?</p>
					<p className="lg:px-20 text-sm lg:text-lg font-light text-slate-400 text-center">Each L15 Index Token tracks the <span className="font-bold text-slate-300">TOP 15</span> cryptocurrencies by market cap at any point, i.e., ~90% of total crypto market. Buying L15 Token is equivalent to buying exposure to virtually the entire crypto ecosystem.</p>
				</div>

				<div className="my-20 flex items-center lg:space-x-10">

					<div className="hidden lg:block lg:w-1/2">
						<img alt="Laser Money App" src="img/l15-screens.png" className="w-full h-auto rounded-2xl" />
					</div>

					<div className="lg:w-1/2 grid grid-cols-1 lg:grid-cols-2 gap-10">
					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">Simple</p>
						<p className="mt-4 text-md font-light text-neutral-900">Don't pick winners and losers - just buy the L15 Token.</p>
					</div>

					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">Save time & money</p>
						<p className="mt-4 text-md font-light text-neutral-900">No need for 15 separate transactions - save on time and fees</p>
					</div>

					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">Auto rebalancing</p>
						<p className="mt-4 text-md font-light text-neutral-900">No stress on what token to buy next: Based on real time data, the Index auto updates itself.</p>
					</div>

					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">Reasonable fees</p>
						<p className="mt-4 text-md font-light text-neutral-900">No entry, exit or performance fees. L15 token only attracts a 0.96% of annual management fees.</p>
					</div>

					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">No lock-in</p>
						<p className="mt-4 text-md font-light text-neutral-900">Exit investment at any time.</p>
					</div>

					<div className="p-6 flex flex-col bg-slate-100/90 rounded-2xl">
						<div className="w-12 h-12 bg-red-500 rounded-xl">
						</div>

						<p className="mt-8 text-xl font-medium text-neutral-900">Transparent</p>
						<p className="mt-4 text-md font-light text-neutral-900">All L15 tokens backed 100% by crypto assets on their native chains; stored in publicly verifiable cold wallets.</p>
					</div>
				</div>
					

				</div>

			</div>

			
			{index ? (
				<div className="mx-10 lg:mx-60 2xl:mx-96 mt-0 lg:mt-28 grid grid-cols-9 gap-y-1 lg:gap-y-10 gap-x-1 lg:gap-x-4 justify-items-center">
					
					<img alt={index[1].name} src={index[1].img} className="hidden lg:block moveUpDown col-start-2 w-14 h-14 rounded-full self-center" />
					<img alt={index[2].name} src={index[2].img} className="hidden lg:block moveUpDown col-start-8 w-14 h-14 rounded-full self-center" />
					
					<img alt={index[3].name} src={index[3].img} className="hidden lg:block moveUpDown col-start-4 w-14 h-14 rounded-full self-center" />
					<img alt={index[4].name} src={index[4].img} className="hidden lg:block moveUpDown col-start-5 w-14 h-14 rounded-full self-center" />
					<img alt={index[5].name} src={index[5].img} className="hidden lg:block moveUpDown col-start-6 w-14 h-14 rounded-full self-center" />

					<img alt={index[6].name} src={index[6].img} className="hidden lg:block moveUpDown col-start-1 w-14 h-14 rounded-full self-center" />
					<img alt={index[7].name} src={index[7].img} className="hidden lg:block moveUpDown col-start-3 w-14 h-14 rounded-full self-center" />
					<img alt={index[0].name} src={index[0].img} className="hidden lg:block col-start-5 w-20 h-20 rounded-full self-center" />
					<img alt={index[8].name} src={index[8].img} className="hidden lg:block moveUpDown col-start-7 w-14 h-14 rounded-full self-center" />
					<img alt={index[9].name} src={index[9].img} className="hidden lg:block moveUpDown col-start-9 w-14 h-14 rounded-full self-center" />

					<img alt={index[10].name} src={index[10].img} className="hidden lg:block moveUpDown col-start-4 w-14 h-14 rounded-full self-center" />
					<img alt={index[11].name} src={index[11].img} className="hidden lg:block hidden lg:block moveUpDown col-start-5 w-14 h-14 rounded-full self-center" />
					<img alt={index[12].name} src={index[12].img} className="hidden lg:block moveUpDown col-start-6 w-14 h-14 rounded-full self-center" />

					<img alt={index[13].name} src={index[13].img} className="hidden lg:block moveUpDown col-start-2 w-14 h-14 rounded-full self-center" />
					<img alt={index[14].name} src={index[14].img} className="hidden lg:block moveUpDown col-start-8 w-14 h-14 rounded-full self-center" />
					
				</div>

			) : (
				<div className="mx-10 lg:mx-60 2xl:mx-96 mt-0 lg:mt-28 grid grid-cols-9 gap-y-10 gap-x-4 justify-items-center">
					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-2 animate-pulse w-14 h-14 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-8 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					
					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-4 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-5 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-6 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>

					<div className="hidden lg:block hidden lg:block hidden lg:block hidden lg:block col-start-1 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block hidden lg:block col-start-3 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block col-start-5 animate-pulse w-20 h-20 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block col-start-7 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block col-start-9 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>

					<div className="hidden lg:block col-start-4 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block col-start-5 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block hidden lg:block hidden lg:block col-start-6 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>

					<div className="hidden lg:block hidden lg:block col-start-2 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>
					<div className="hidden lg:block col-start-8 animate-pulse w-10 h-10 bg-neutral-700 rounded-full"></div>

				</div>
			)}
			

			

			{/* Section 5 - Passive + Active Strategies */}

			<div className="mx-6 lg:mx-20 2xl:mx-28 mt-20 lg:mt-28 px-4 py-10 lg:p-10">

				<div className="flex flex-col items-center space-y-4">
					<p className="text-2xl lg:text-4xl font-medium text-slate-300 text-center">Passive + Active Strategies with L15 Token</p>
					<p className="px-20 text-lg font-light text-slate-400 text-center"></p>
				</div>

				<div className="mt-20 grid grid-cols-1 lg:grid-cols-8 gap-8 2xl:gap-10">

					<div className="lg:col-span-2 p-6 bg-neutral-900 rounded-xl shadow-lg shadow-blue-500/75">
						<p className="text-lg text-slate-300">Passive Strategy</p>

						<p className="my-8 lg:my-10 text-sm lg:text-lg text-slate-300">Buy and hold the L15 tokens - passive index investing strategy.</p>

						<Link to="/app">
						<button className="flex items-center space-x-2 bg-white w-fit px-8 py-3 text-sm font-medium text-neutral-900 rounded-full">
							<p>INVEST NOW</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-3.5 h-3.5 text-neutral-900">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
							</svg>
						</button>
						</Link>

					</div>

					<p className="text-6xl font-bold text-slate-300 text-center self-center">+</p>

					<div className="lg:col-span-5 p-6 bg-neutral-900 rounded-xl shadow-lg shadow-orange-500/75">
						<div className="flex items-center space-x-4">
							<p className="text-lg text-slate-300">Active Strategy</p>

							<div className="w-fit px-2 py-1 bg-slate-400 rounded-md">
								<p className="text-xs text-neutral-800">Coming soon!</p>
							</div>
						</div>

						<div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-8 2xl:gap-10">
							<div className="p-4 bg-orange-100 rounded-xl">
								<div className="mb-6 w-10 h-10 flex flex-col items-center justify-center bg-orange-300 rounded-xl">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-orange-600 fill-orange-600">
  										<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
									</svg>
								</div>

								<p className="text-lg font-medium text-neutral-900">Stake</p>
								<p className="mt-3 text-sm font-light text-neutral-900">Earn a portion of the fees on all trades of L15 Token on exchanges.</p>
							</div>

							<div className="p-4 bg-blue-100 rounded-xl">
								<div className="mb-6 w-10 h-10 flex flex-col items-center justify-center bg-blue-400 rounded-xl">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-blue-900">
  										<path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
									</svg>
								</div>

								<p className="text-lg font-medium text-neutral-900">Delegate</p>
								<p className="mt-3 text-sm font-light text-neutral-900">Delegate assets underlying the L15 Tokens to miners/validators and earn returns.</p>
							</div>

							<div className="p-4 bg-green-100 rounded-xl">
								<div className="mb-6 w-10 h-10 flex flex-col items-center justify-center bg-green-400 rounded-xl">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-900">
  										<path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
									</svg>
								</div>

								<p className="text-lg font-medium text-neutral-900">Borrow</p>
								<p className="mt-3 text-sm font-light text-neutral-900">Collateralize and borrow against the L15 Tokens.</p>
							</div>
						</div>
					</div>

				</div>

			</div>

			{/*<div className="my-20 mx-6 lg:mx-20 2xl:mx-28 grid grid-cols-1 lg:grid-cols-2 gap-6">

				<div className="p-10 bg-neutral-800 rounded-xl">
					<p className="pl-10 text-xl text-slate-100">Value of $100 invested on January 1st 2023 would be</p>

					<table class="mt-6 table-auto text-lg text-slate-100">
					  <thead>
					    <tr>
					      <th className="px-10 py-2">Asset</th>
					      <th className="px-10 py-2">Value</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr>
					      <td className="px-10 py-2">L15</td>
					      <td className="px-10 py-2">$169</td>
					    </tr>
					    <tr>
					      <td className="px-10 py-2">NIFTY</td>
					      <td className="px-10 py-2">$100</td>
					    </tr>
					    <tr>
					      <td className="px-10 py-2">S&P</td>
					      <td className="px-10 py-2">$100</td>
					    </tr>
					    <tr>
					      <td className="px-10 py-2">GOLD</td>
					      <td className="px-10 py-2">$100</td>
					    </tr>
					  </tbody>
					</table>
					
				</div>

				<div className="flex flex-col items-center self-center">
					<LineChart 
						width={500} 
						height={200} 
						data={chartData} 
						margin={{top: 5, right: 10, left: 10, bottom: 5}}
						>
						<Line dataKey="l15" stroke="#14b8a6" dot={false} />
						<Line dataKey="nifty" stroke="#3b82f6" dot={false} />
						<Line dataKey="snp" stroke="#a855f7" dot={false} />
						<Line dataKey="gold" stroke="#eab308" dot={false} />

						<Tooltip />
						<Legend />
						
	  					<YAxis hide="false" type="number" domain={[chartData[0]['l15'], 'dataMax']} />
					</LineChart>
				</div>

			</div>*/}

			<div className="mx-6 lg:mx-20 2xl:mx-28 mt-20 p-10 lg:p-14 bg-red-500 rounded-3xl">
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-10">

					<div className="flex flex-col space-y-10">
						<p className="text-3xl lg:text-5xl font-semibold text-white">The Evolution of Web3: From Digital Currency to Digital Economies</p>

						{/*<button className="bg-white w-fit px-10 py-3 font-medium text-neutral-900 rounded-full">INVEST NOW</button>*/}
						<Link to="/app" target="_blank" rel="noopener noreferrer" className="hidden lg:block">
						<button className="flex items-center space-x-2 lg:bg-white w-fit px-10 py-4 text-md font-medium text-neutral-900 rounded-full">
							<p className="hidden lg:block">INVEST NOW</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-neutral-900 hidden lg:block">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
							</svg>
						</button>
						</Link>
					</div>

					<div className="flex flex-col">
						<p className="text-sm lg:text-lg font-light text-white">Web3 has come a long way since the inception of Bitcoin. While the initial focus was on using blockchain technology to create a decentralized digital currency, the web3 space has since evolved to encompass much more.</p>
						
						<div className="mt-6 grid grid-cols-2 lg:grid-cols-3 gap-8">
							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">1.2T+</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">Total Market Cap.</p>
							</div>

							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">30,000%</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">Bitcoin Growth</p>
							</div>

							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">10K+</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">Tokens in circulation</p>
							</div>

							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">45B+</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">DeFi Market Cap.</p>
							</div>

							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">14K+</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">Dapps</p>
							</div>

							<div className="flex flex-col">
								<p className="text-2xl lg:text-4xl font-semibold text-white">400M+</p>
								<p className="mt-2 text-xs lg:text-sm text-slate-100">Users</p>
							</div>
						</div>

						{/*<p className="mt-6 text-xs font-light text-slate-100">SOURCE: Coingecko, Dappradar</p>*/}
					</div>

					<Link to="/app" className="lg:hidden">
					<button className="lg:hidden flex items-center space-x-2 bg-white w-fit px-10 py-4 text-md font-medium text-neutral-900 rounded-full">
						<p className="lg:hidden">INVEST NOW</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-neutral-900 lg:hidden">
								<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
						</svg>
					</button>
					</Link>

				</div>
			</div>

			{/* Section 4 - How to buy */}

			<div className="mx-6 lg:mx-20 2xl:mx-28 mt-20 px-4 py-10 lg:p-10 lg:bg-neutral-950 rounded-2xl">

				<div className="flex flex-col items-center space-y-4">
					<p className="text-2xl lg:text-4xl font-medium text-slate-300 text-center">How to buy L15 Token?</p>
					<p className="px-20 text-lg font-light text-slate-400 text-center"></p>
				</div>


				<div className="mt-10 grid grid-cols-1 lg:grid-cols-6 2xl:grid-cols-4 gap-10">
					<div className="lg:col-start-2 2xl:col-start-2 col-span-2 2xl:col-span-1 px-4 py-6 flex flex-col bg-slate-300 rounded-xl space-y-4">
						<div className="w-full h-28 flex flex-col items-center justify-center bg-green-400 rounded-xl">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 text-green-800">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
						</div>

						<p className="text-xl font-medium text-neutral-900">Buy with Fiat</p>

						<div className="w-fit px-2 py-1 bg-slate-400 rounded-md">
							<p className="text-xs text-neutral-800">INR</p>
						</div>

						<p className="text-sm text-neutral-900">Login using your Google account and complete KYC. Make payment using IMPS and receive your L15 tokens in Laser Wallet.</p>

						<Link to="/app" className="flex flex-col items-center py-3 text-md font-medium text-white bg-red-500 rounded-xl"><button className="text-md font-medium bg-red-500 rounded-xl">Invest now</button></Link>
					</div>
					<div className="col-span-2 2xl:col-span-1 px-4 py-6 flex flex-col bg-slate-300 rounded-xl space-y-4">
						<div className="w-full h-28 flex flex-col items-center justify-center bg-orange-500/75 rounded-xl">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 text-green-800">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
						</div>

						<p className="text-xl font-medium text-neutral-900">Buy on-chain</p>

						<div className="flex items-center space-x-2">
							<div className="w-fit px-2 py-1 bg-slate-400 rounded-md">
								<p className="text-xs text-neutral-800">Ehereum</p>
							</div>
							<div className="w-fit px-2 py-1 bg-slate-400 rounded-md">
								<p className="text-xs text-neutral-800">Polygon</p>
							</div>
							<div className="w-fit px-2 py-1 bg-slate-400 rounded-md">
								<p className="text-xs text-neutral-800">Arbitrum</p>
							</div>
						</div>

						<p className="text-sm text-neutral-900">Coming soon!</p>
					</div>
				</div>

			</div>

			{/* Section 6 - Partners */}

			<div className="mx-6 lg:mx-20 2xl:mx-28 mt-20 p-10">

				<div className="flex flex-col items-center space-y-4">
					<p className="text-4xl font-medium text-slate-300">Our Partners</p>
					<p className="px-20 text-lg font-light text-slate-400 text-center"></p>
				</div>

				<div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-10">
					<img alt="Polygon" src="img/partners/polygon.png" className="w-full lg:w-3/4 rounded-xl justify-self-center" />
					{/*<img alt="Arbitrum" src="img/partners/arbitrum.png" className="w-full rounded-xl" />*/}
					<img alt="Kucoin" src="img/partners/kucoin.png" className="w-full lg:w-3/4 rounded-xl justify-self-center" />
					<img alt="Onmeta" src="img/partners/onmeta.png" className="w-full lg:w-3/4 rounded-xl justify-self-center" />
				</div>

			</div>

			{/* Section 7 - Reach Us */}

			<div className="mx-10 lg:mx-20 2xl:mx-28 mt-10 p-10">

				<div className="flex flex-col items-center space-y-4">
					<p className="text-4xl font-medium text-slate-300">Reach Us</p>
					<p className="px-20 text-lg font-light text-slate-400 text-center"></p>
				</div>

				<div className="mt-10 grid grid-cols-1 lg:grid-cols-6 2xl:grid-cols-4 gap-10">
					<div className="lg:col-start-2 col-span-2 2xl:col-start-2 2xl:col-span-1 p-6 lg:p-10 flex flex-col bg-slate-300 rounded-xl">
						<div className="w-14 h-14 flex flex-col items-center justify-center bg-cyan-400 rounded-xl">
							<img alt="Twitter" src="img/twitter.png" className="w-8 h-8" />
						</div>

						<p className="mt-8 text-lg lg:text-2xl font-medium text-neutral-900">@laser_money</p>
					</div>

					<div className="col-span-2 2xl:col-span-1 p-6 lg:p-10 flex flex-col bg-slate-300 rounded-xl">
						<div className="w-14 h-14 flex flex-col items-center justify-center bg-blue-500/75 rounded-xl">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-blue-800">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
							</svg>
						</div>

						<p className="mt-8 text-lg lg:text-2xl font-medium text-neutral-900">team@laser.money</p>
					</div>
				</div>

			</div>

			<div className="mx-10 lg:mx-20 2xl:mx-28 mt-10 lg:mt-20 p-10">
				<p className="text-2xl lg:text-4xl font-light text-red-500 text-center">Let's get you started with Laser Money!</p>
				<p className="mb-10 lg:mb-20 mt-4 text-sm lg:text-xl font-light text-slate-300 text-center">Explore Laser Docs to know more or directly start investing.</p>

				<div className="lg:hidden grid grid-cols-1 gap-6 justify-items-center">
					<Link to="//docs.laser.money" target="_blank" rel="noopener noreferrer">
						<button className="flex items-center space-x-2 bg-neutral-900 border border-slate-300 w-fit px-6 py-3 text-sm font-medium text-white rounded-full">
							<p>EXPLORE DOCS</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-white">
	  							<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
							</svg>
						</button>
					</Link>

					<Link to="/app">
						<button className="flex items-center space-x-2 bg-white w-fit px-6 py-3 text-sm font-medium text-neutral-900 rounded-full">
							<p>INVEST NOW</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-neutral-900">
									<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
							</svg>
						</button>
					</Link>
				</div>

				<div className="flex flex-col items-center">
					<div className="flex items-center space-x-4">
						<Link to="//docs.laser.money" target="_blank" rel="noopener noreferrer" className="hidden lg:block">
						<button className="flex items-center space-x-2 bg-neutral-900 border border-slate-300 w-fit px-10 py-4 text-md font-medium text-white rounded-full">
							<p className="hidden lg:block">EXPLORE DOCS</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-white hidden lg:block">
	  							<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
							</svg>
						</button>
						</Link>

						<Link to="/app" className="hidden lg:block">
						<button className="flex items-center space-x-2 bg-white w-fit px-10 py-4 text-md font-medium text-neutral-900 rounded-full">
							<p className="hidden lg:block">INVEST NOW</p>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4 text-neutral-900 hidden lg:block">
									<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
							</svg>
						</button>
						</Link>
					</div>
				</div>
			</div>

			{/* Section 8 - Footer */}

			{/*<div className="mt-20 p-8 flex items-center border-t border-slate-300/25">
				<p className="text-sm font-light text-slate-400">Copyrights reserved 2023. Laser.Money</p>

				<div className="ml-auto flex items-center space-x-4">
					<p className="text-sm font-light text-slate-400">Docs</p>
					<p className="text-sm font-light text-slate-400">Legal</p>	
					<p className="text-sm font-light text-slate-400">Contact</p>
					<p className="text-sm font-light text-slate-400">Twitter</p>	
					<p className="text-sm font-light text-slate-400">Invest</p>	
				</div>
			</div>*/}

			<div className="mt-20 p-8 grid grid-cols-1 lg:grid-cols-2 gap-6 border-t border-slate-300/25">
				<p className="text-sm font-light text-slate-400 justify-self-center lg:justify-self-start">Copyrights reserved 2023. Laser.Money</p>

				<div className="lg:ml-auto flex items-center space-x-4 justify-self-center lg:justify-self-start">
					<Link to="//docs.laser.money"><p className="text-sm font-light text-slate-400">Docs</p></Link>
					<Link to="//docs.laser.money/legal"><p className="text-sm font-light text-slate-400">Legal</p></Link>
					<Link to="//docs.laser.money/reach-us"><p className="text-sm font-light text-slate-400">Contact</p></Link>
					<Link to="//twitter.com/@laser_money"><p className="text-sm font-light text-slate-400">Twitter</p></Link>
					<Link to="/app"><p className="text-sm font-light text-slate-400">Invest</p></Link>
				</div>
			</div>

			

		</>

	)
}

export default LandingNew;