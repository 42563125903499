import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from 'firebase/auth';

const firebaseConfig = {
	apiKey: "AIzaSyCC3SA7zNmjjCB4eLz4LekHed5EpfJi1S0",
	authDomain: "symply-app.firebaseapp.com",
	projectId: "symply-app",
	storageBucket: "symply-app.appspot.com",
	messagingSenderId: "522867820553",
	appId: "1:522867820553:web:4437e00fd119391bd550d8",
	measurementId: "G-7PVKTJ4JW7"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const sign_out = () => {
	console.log('Sign out started...')
	signOut(auth).then(() => {
		window.location.href = '/';
	}).catch((error) => {
		console.log('Error logging out');
		console.log(error.toString())
	});
}

export {app, auth, sign_out};