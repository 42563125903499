import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const KycWelcome = () => {
	useEffect(() => {
		document.title = 'LASER.MONEY | KYC | WELCOME'
	}, [])

	return (
		<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
			<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">
				
				<div className="mt-10 lg:mt-0 w-20 h-20 flex flex-col items-center justify-center bg-green-200 rounded-xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 text-green-800">
  						<path strokeLinecap="round" strokeLinejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
					</svg>
				</div>

				<p className="mt-10 lg:mt-10 text-3xl text-slate-300">Verify your identity!</p>
				<p className="mt-6 lg:mb-20 text-md font-light text-slate-400">Please complete your KYC before investing. Keep your <span className="font-bold">PAN</span> and <span className="font-bold">Aadhaar</span> number handy.</p>

				<div className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
					<Link to='/kyc-pan'>
					<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
						<p className="text-lg text-slate-100">NEXT</p>
					</button>
					</Link>
				</div>

			</div>
		</div>
	)
}

export default KycWelcome;