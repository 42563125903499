import { useEffect, useState } from 'react';

// Components
import Navigation from '../components/navigation';

const Test = () => {

	const [amount, setAmount] = useState('')

	useEffect(() => {
		document.title = 'LASER.MONEY | TEST'
	}, [])

	const handleKeypress = e => {
		if(e.key === 'Enter') {
			e.target.blur()
		}
	}

	return (
		<>
			<div class="">
				
				{/*<Navigation nav="main" />*/}

				<div className="p-6 flex items-center fixed inset-x-0 top-0 bg-neutral-700">
					
					<div className="w-10 h-10 flex flex-col items-center justify-center rounded-full bg-red-600">

						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
	  						<path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
						</svg>

					</div>

				</div>

				<div className="mt-28 mx-6 flex flex-col space-y-10">
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>
					<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Test line...</p>

				</div>
				
				<div class="fixed inset-x-0 bottom-0 p-6 w-full h-50% bottom-0 left-0 bg-neutral-800 rounded-t-3xl">
					<div className="flex items-center">
						{/*<p className="text-lg text-slate-100">INVEST</p>*/}
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-100">
  							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</div>

					<div className="overflow-auto mt-4 flex flex-col space-y-6 max-h-60">
						<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">Cryptocurrency</p>
						<p className="bg-neutral-700 p-3 rounded-xl text-slate-100">INR</p>
					</div>

					
				</div>
			</div>
		</>
	)
}

export default Test;