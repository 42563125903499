import { useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

import Navigation from '../components/navigation'
import Loader from '../components/loader';
import PageLoad from '../components/page_load'
import Help from '../components/help'

import { API_HOST } from '../config_vars';

const UpdateUTR = (props) => {

	const [utr, setUtr] = useState('')
	const [err, setErr] = useState('')
	const [loading, setLoading] = useState(false)
	const [pageLoad, setPageLoad] = useState(true)
	
	const location = useLocation();
	const qsData = qs.parse(location.search);

	useEffect(() => {

		document.title = 'LASER.MONEY | UPDATE UTR'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})

	},[]);

	const update_utr = () => {
		
		setLoading(true)

		fetch(API_HOST + '/order/fiat/update-utr', {
			method: 'POST',
			headers: {
				'Authorization': localStorage.getItem('accessToken'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({order_id: qsData.oid, utr: utr})
		})
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					window.location.href = '/transactions'
				} else {
					setLoading(false)
					setErr('Error updating transaction UTR.')
				}
			},
			(error) => {
				setLoading(false)
				setErr('Error updating transaction UTR.')
			}
		)
	}

	const handleKeypress = e => {
		if(e.key === 'Enter') {
			update_utr()
			e.target.blur()
		}
	}



	

	return (

		<>
			{ pageLoad ? (
				<PageLoad />
			) : (
				<div className="m-6 xl:m-10">
					<Navigation nav='transactions' />

					<div id="inrDiv" className="my-20 flex flex-col items-center justify-center mx-auto lg:mt-20">
						<div className="md:bg-neutral-900 w-full lg:w-1/3 2xl:w-1/4 px-4 lg:px-10 lg:py-10 md:rounded-lg">

							<div className="flex items-center">
								<p className="mr-2 text-2xl font-semibold text-slate-300">Update UTR</p>
							</div>

							
							<p className="mt-6 text-sm font-light text-slate-400">ORDER ID #</p>
							<p className="text-sm font-medium text-slate-300">{qsData.oid}</p>

							<p className="mt-6 text-sm font-light text-slate-400">AMOUNT</p>
							<p className="text-sm font-medium text-slate-300">{qsData.amount} INR</p>

							<p className="mt-6 text-sm font-light text-slate-400">PRODUCT</p>
							<p className="text-sm font-medium text-slate-300">{qsData.product}</p>
							
							<p className="mt-6 text-sm font-light text-slate-400">UTR</p>
							<div className="flex items-center">
								<input type="text" inputMode="numeric" pattern="[0-9]*" value={utr} onChange={(e) => setUtr(e.target.value)} className="pl-0 w-full text-2xl font-semibold tracking-wide border-none focus:ring-0 bg-neutral-950 md:bg-neutral-900 text-slate-400" placeholder="3120XXXXXXXX" maxLength="12" />
							</div>

							<p className="mt-4 text-xs text-slate-400">IMPS UTR: 12 digit</p>	
							<p className="mt-4 text-xs text-slate-400">Transaction can take upto 48 hours to process.</p>

							<div className="mt-28 sm:hidden mx-10 mb-10 fixed inset-x-0 bottom-0">
								{err && <p className="mt-4 mb-2 text-sm text-yellow-400">{err}</p>}

								{
									loading ? (
										<Loader />
									) : (
										<button onClick={update_utr} className="mt-28 text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Submit</button>
									)
								}
							</div>

							<div className="mt-10 hidden sm:block">
								{err && <p className="mt-4 mb-2 text-sm text-yellow-400">{err}</p>}

								{
									loading ? (
										<Loader />
									) : (
										<button onClick={update_utr} className="text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Submit</button>
									)
								}
							</div>
							
						</div>
				    </div>

				    

				</div>
			)}

			<Help />
		</>

	)
}

export default UpdateUTR;