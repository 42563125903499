import { useEffect, useState } from 'react';

import { sign_out } from '../firebase-config';

const Logout = () => {

	// const [loading, setLoading] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | Logout'

		sign_out()
	})

	// return (
	// 	{if (loading) <Loading />}
	// )

}

export default Logout;