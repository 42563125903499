import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

import Navigation from '../components/navigation'
import PageLoad from '../components/page_load'
import Help from '../components/help'

import { API_HOST } from '../config_vars';

const Txns = () => {

	const [txns, setTxns] = useState('')
	const [txnType, setTxnType] = useState('pending')
	const [err, setErr] = useState('')
	const [toast, setToast] = useState('')
	const [pageLoad, setPageLoad] = useState(true)

	const get_txns = (tt) => {

		setTxns('')

		fetch(API_HOST + '/order/fiat/txns', {
			method: 'POST',
			headers: {
				'Authorization': localStorage.getItem('accessToken'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({txn_type: tt})
		})
		.then(res => res.json())
		.then(
			(data) => {
				if(data.code === 200) {
					setTxns(data)
					// console.log(data)
				} else {
					setErr('Error fetching transaction data')
				}
			},
			(error) => {
				setErr('Error fetching transaction data')
			}
		)
	}

	const update_utr = (oid, amount, product) => {
		window.location.href = '/update-utr?oid='+oid+'&amount='+amount+'&product='+product
	}

	const txnTabHandler = (ttype) => {
		// console.log(ttype)
		setTxnType(ttype)
		get_txns(ttype)
	}

	const location = useLocation();

	const qsData = qs.parse(location.search);

	useEffect(() => {
		document.title = 'LASER.MONEY | Transactions';

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})

		if(qsData.t) {
			txnTabHandler(qsData.t)
		} else {
			// get_txns('pending')
			txnTabHandler('pending')
		}

		if(toast) {
			setTimeout(() => {
				setToast('')
    			console.log('Toast!')
  			}, 2000);
		}


	}, []);

	

	return (

		<>
			{ pageLoad ? (
				<PageLoad />
			) : (
				<div className="m-6 lg:m-10">
					<Navigation dash='main' />

					<p className="mt-20 xl:m-12 text-xl font-medium text-slate-300">Transaction History</p>

					<div className="flex flex-col items-center">
					<div className="my-10 w-full lg:w-1/3 flex items-center bg-neutral-900 rounded-xl">
						{ txnType === 'deposit' ? (
							<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-gradient-to-r from-red-500 to-yellow-500 rounded-lg">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
			  						<path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
								</svg>
								<p className="text-xs font-medium">Deposits</p>
							</div>
							) : (
							<div onClick={() => txnTabHandler('deposit')} className="cursor-pointer w-1/3 flex items-center justify-center space-x-2">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-300">
			  						<path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
								</svg>
								<p className="text-xs font-light text-slate-300">Deposits</p>
							</div>
						)}

						{ txnType === 'withdraw' ? (
							<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-gradient-to-r from-red-500 to-yellow-500 rounded-lg">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
		  							<path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
								</svg>
								<p className="text-xs font-medium">Withdrawals</p>
							</div>
							) : (
							<div onClick={() => txnTabHandler('withdraw')} className="cursor-pointer w-1/3 flex items-center justify-center space-x-2">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-300">
		  							<path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
								</svg>
								<p className="text-xs font-light text-slate-300">Withdrawals</p>
							</div>
						)}

						{ txnType === 'pending' ? (
							<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-gradient-to-r from-red-500 to-yellow-500 rounded-lg">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
		  							<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
								</svg>
								<p className="text-xs font-medium">Pending</p>
							</div>
							) : (
							<div onClick={() => txnTabHandler('pending')} className="cursor-pointer w-1/3 flex items-center justify-center space-x-2">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-300">
		  							<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
								</svg>
								<p className="text-xs font-light text-slate-300">Pending</p>
							</div>
						)}
					
					</div>
					</div>

					{ txns ? (

						<div className="mb-32 flex flex-col items-center space-y-8">

						{
							txns.data.map(x => {
								return (
									<>
										<div className="w-full lg:w-1/3 p-6 bg-neutral-900 rounded-lg">
											<p className="text-sm font-medium text-slate-300">#{x._id}</p>
											
											<div className="mt-6 flex items-center space-x-4">
												<div className="w-1/2">
													<p className="text-md font-medium text-yellow-400">{x.status.last_status.status}</p>
													<p className="text-xs text-slate-400">Order status</p>
												</div>
												<div className="w-1/2">
													<p className="text-md font-medium text-red-400">{x.product.name}</p>
													<p className="text-xs text-slate-400">Product</p>
												</div>
											</div>

											<div className="mt-6 flex items-center space-x-4">
												<div className="w-1/2">
													<p className="text-md font-medium text-slate-300">{x.status.created_on.dt.date}</p>
													<p className="text-xs text-slate-400">Created on</p>
												</div>

												<div className="w-1/2">
													<p className="text-md font-medium text-slate-300">{x.status.updated_on.dt.date}</p>
													<p className="text-xs text-slate-400">Updated on</p>
												</div>
											</div>

											<div className="mt-6 flex items-center space-x-4">
												<div className="w-1/2">
													<p className="text-md font-medium text-slate-300">Fiat (INR)</p>
													<p className="text-xs text-slate-400">Payment Method</p>
												</div>

												<div className="w-1/2">
													<p className="text-md font-medium text-green-400">{x.fiat.amount}</p>
													<p className="text-xs text-slate-400">Order value (INR)</p>
												</div>
											</div>

											<div className="mt-6 flex items-center space-x-4">
												<div className="w-1/2">
													<p className="text-md font-medium text-slate-300">L15</p>
													<p className="text-xs text-slate-400">Token</p>
												</div>

												<div className="w-1/2">
													<p className="text-md font-medium text-slate-300">{x.output.amount}</p>
													<p className="text-xs text-slate-400">Tokens received</p>
												</div>
											</div>

											{ x.status.last_status.status === 'Created' && 
												<div className="mt-6 flex items-center space-x-4">
													{/* <button className="py-1 w-1/2 border border-red-500 rounded-full text-sm text-slate-400">Cancle</button> */}
													<button onClick={() => update_utr(x._id, x.fiat.amount, x.product.name)} className="py-1 w-1/2 border border-red-500 bg-red-500 rounded-full text-sm text-slate-300">Update UTR</button>
												</div>
											}

										</div>
									</>
								)
							})
						}	
											
						</div>
						
					) : (
						<div className="flex flex-col items-center space-y-8">
							<div className="w-full xl:w-1/3 p-6 bg-neutral-900 rounded-lg">
								<div className="animate-pulse w-1/2 h-2 bg-neutral-800 rounded-lg"></div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>
							</div>

							<div className="w-full xl:w-1/3 p-6 bg-neutral-900 rounded-lg">
								<div className="animate-pulse w-1/2 h-2 bg-neutral-800 rounded-lg"></div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>

								<div className="mt-4 flex items-center space-x-2">
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
									<div className="animate-pulse w-1/2 h-4 bg-neutral-800 rounded-lg"></div>
								</div>
							</div>
						</div>
					)}

					{ toast && 
						<div className="fixed inset-x-0 bottom-28 flex flex-col items-center">
							<div className="p-3 w-1/2 bg-red-200 rounded-xl">
								<p className="text-sm text-neutral-700 text-center">{toast}</p>
							</div>
						</div>
					}

				</div>
			)}

			<Help />
		</>
		
	)
}

export default Txns;