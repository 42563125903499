import { useEffect, useState } from 'react';

import { API_HOST } from '../../config_vars';

const Portfolio = (props) => {

	const [l15, setL15] = useState('')
	const [usd, setUSD] = useState('')

	useEffect(() => {

		fetch(API_HOST + '/user/portfolio', {
			headers: {
				'Authorization': localStorage.getItem('accessToken')
			}
		})
		.then(res => res.json())
		.then(
			(data_l15) => {
				if(data_l15.code === 200) {

					// console.log(data_l15.data.l15)

					setL15(data_l15.data.l15)

					fetch(API_HOST + '/l15/nav')
					.then(res => res.json())
					.then(
						(data_nav) => {
							if(data_nav.code === 200) {
								setUSD((data_nav.data.nav * data_l15.data.l15).toFixed(2))
							}
						},
						(error) => {
							setUSD('')
						}
					)
				}
			},
			(error) => {
				setL15('')
			}
		)

	}, [])

	return (
		<div className="px-6 mt-20">
			<p className="pt-4 text-xs font-light text-slate-400">CURRENT HOLDING</p>
			<div className="mt-4 w-full flex items-center space-x-4">
				<div className="w-1/2 p-3 flex flex-col bg-neutral-800/75 rounded-2xl">
					<p className="text-xs font-light text-slate-400">TOKENS</p>
					{ l15 ? (
						<p className="mt-1 text-lg font-medium text-slate-300">{l15} <span className="text-xs font-light text-slate-400">L15</span></p>
					) : (
						<div className="animate-pulse mt-2 w-3/4 h-4 bg-neutral-700 rounded-lg"></div>
					)}
				</div>
				<div className="w-1/2 p-3 flex flex-col bg-neutral-800/75 rounded-2xl">
					<p className="text-xs font-light text-slate-400">VALUE</p>
					{ usd ? (
						<p className="mt-1 text-lg font-medium text-slate-300">{usd} <span className="text-xs font-light text-slate-400">USD</span></p>
					) : (
						<div className="animate-pulse mt-2 w-3/4 h-4 bg-neutral-700 rounded-lg"></div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Portfolio;