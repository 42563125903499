import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';

import App from './App';

import './index.css';

export default function Main() {

  document.body.classList.add('bg-neutral-950');

  return (

    <App />
    
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);