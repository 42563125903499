import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { API_HOST } from '../../config_vars';

const KycPAN = () => {

	const [pan, setPan] = useState('')
	const [err, setErr] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		document.title = 'LASER.MONEY | KYC | PAN'
	}, [])

	const verify_pan = () => {

		setErr('')
		setLoading(true)

		fetch(API_HOST + '/kyc/pan', {
			method: 'POST',
			body: JSON.stringify({
				pan: pan
			}),
			headers: {
				'Accept': 'application/json',
      			'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('accessToken')
			}
		})
		.then(res => res.json())
		.then(
		    (result) => {
		    	console.log(result)

		    	if(result.code === 200) {
					setLoading(false)
					window.location.href = '/kyc-aadhaar'
		    	} else {
					setLoading(false)
					setErr('Error verifying PAN.')
		    	}
		    	
		    },
		    (error) => {
				console.log(error)
				setLoading(false)
				setErr('Error verifying PAN.')
		    }
		)

	}

	const handleKeypress = e => {
		if (e.key === 'Enter') {
			verify_pan()
			e.target.blur()
		}
	}

	return (
		<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
			<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">
				
				<div className="mt-10 lg:mt-0 w-20 h-20 flex flex-col items-center justify-center bg-green-200 rounded-xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 text-green-800">
  						<path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
					</svg>
				</div>

				<p className="mt-10 text-3xl text-slate-300">Enter PAN</p>
				{/*<p className="mt-6 lg:mb-20 text-md font-light text-slate-400">Please complete your KYC before investing. Keep your <span className="font-bold">PAN</span> and <span className="font-bold">Aadhaar</span> number handy.</p>*/}

				<input value={pan} onChange={(e) => setPan(e.target.value)} onKeyPress={handleKeypress} className="uppercase mt-6 lg:mb-20 px-4 py-3 text-2xl font-medium text-slate-100 bg-neutral-800 rounded-xl tracking-wide border-none focus:ring-0" maxLength="10" placeholder="AUIXXXXXXX" />

				<div className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
					
					{err && <p className="mb-4 text-sm text-orange-400">{err} Try again or <Link to="https://docs.laser.money/reach-us" target="_blank" className="text-red-500">contact us.</Link></p>}

					{ loading ? (
						<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
							<div className="flex items-center space-x-4">
								<div className="flex flex-col items-center">
	                				<div className="w-6 h-6 rounded-full animate-spin border-2 border-solid border-slate-100 border-t-transparent"></div>
	            				</div>
								<p className="text-lg text-slate-100">VERIFYING</p>
							</div>
						</button>
					) : (
						<button onClick={verify_pan} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
							<p className="text-lg text-slate-100">VERIFY PAN</p>
						</button>
					)}
				</div>

			</div>
		</div>
	)
}

export default KycPAN;