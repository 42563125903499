import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// Components
import Navigation from '../components/navigation'

const Menu = () => {

	const navigate = useNavigate();

	useEffect(() => {
		document.title = 'LASER.MONEY | MENU'
	}, [])

	const open_txns = () => {
		window.location.href = '/txns';
	}

	return (
		<>
			<Navigation />

			<div className="px-6 my-24 flex flex-col space-y-6">

				<div onClick={() => navigate('/transactions')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl focus:ring-0 focus:outline-0">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
	  							<path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Transaction History</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

				<div onClick={() => navigate('/profile')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl focus:ring-0 focus:outline-0">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Profile</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

				<div onClick={() => navigate('/help')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Help</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

				<div onClick={() => window.open('//docs.laser.money')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Docs</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

				<div onClick={() => window.open('//docs.laser.money/legal')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Legal</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

				<div onClick={() => navigate('/logout')} className="px-4 py-3 w-full bg-neutral-900 rounded-xl">
					<div className="cursor-pointer flex items-center">
						<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
  								<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
							</svg>
						</div>
						<p className="ml-4 text-md text-slate-300">Logout</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
							<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
						</svg>
					</div>
				</div>

			</div>
		</>
	)
}

export default Menu;