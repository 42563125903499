import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

// Components
import Navigation from '../components/navigation'
import Loader from '../components/loader'
import PageLoad from '../components/page_load'
import Help from '../components/help'

import { MIN_INVEST, API_HOST } from '../config_vars'

const products = {
	'l15': {
		'pid': 'l15',
		'name': 'L15 Index',
		'img': 'img/logo/laser_sq_logo.png',
		'msg': 'Passive Index strategy',
		'input_token': 'USDT'
	},
	'glp': {
		'pid': 'glp',
		'name': 'GLP',
		'img': 'img/crypto/svg/glp.svg',
		'msg': 'DeFi strategy on Arbitrum',
		'input_token': 'USDT'
	},
	'dai': {
		'pid': 'dai',
		'name': 'DAI',
		'img': 'img/crypto/png/dai.png',
		'msg': 'DeFi strategy on Arbitrum',
		'input_token': 'DAI'
	}
}

const Invest = (props) => {

	const [quote, setQuote] = useState('')
	const [amount, setAmount] = useState('')
	const [oid, setOid] = useState('')
	const [err, setErr] = useState()
	const [btnState, setBtnState] = useState(true)
	const [inrDiv, setInrDiv] = useState(true)
	const [loading, setLoading] = useState(false)
	const [tab, setTab] = useState(1)

	const [pageLoad, setPageLoad] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | INVEST'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})

	}, [])

	const handleKeypress = e => {
		if(e.key === 'Enter') {
			e.target.blur()
		}
	}

	// const txnTabHandler = (ttype) => {
	// 	setTab(ttype)
	// }

	const fetchQuote = (val) => {
		setAmount(val)
		setQuote('')
		setErr('')
		setBtnState(true)

		if (val >= MIN_INVEST) {

			fetch(API_HOST + '/data/quote', {
				method: 'POST',
				headers: {
					'Authorization': localStorage.getItem('accessToken'),
					'Content-type': 'application/json'
				},
				body: JSON.stringify({product: props.product, amount: val})
			})
			.then(res => res.json())
			.then(
				(data) => {
					console.log(data)

					if(data.code === 200) {
						setQuote(data)
						setBtnState(false)
					} else {
						setQuote('')
						setErr(data.msg)
					}
				},
				(error) => {
					setQuote('')
					setErr('Error fetching quote')
				}
			)
		} else {
			setErr('Minimum order allowed is ' + MIN_INVEST + ' INR.')
		}
	}

	const createOrder = () => {

		setLoading(true)

		fetch(API_HOST + '/order/fiat/deposit', {
			method: 'POST',
			headers: {
				'Authorization': localStorage.getItem('accessToken'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({pid: props.product, amount: amount})
		})
		.then(res => res.json())
		.then(
			(data) => {
				console.log(data)
				if(data.code === 200) {
					// alert('Order created')
					// window.location.href = '/transactions'
					setOid(data.oid)
					setLoading(false)
					setInrDiv(false)
				} else {
					setLoading(false)
					console.log(data.msg)
				}
			},
			(error) => {
				setLoading(false)
				console.log(error)
				console.log('Error creating order :(')
			}
		)
	}

	const update_utr = () => {
		window.location.href = '/update-utr?oid='+oid+'&amount='+amount+'&product='+products[props.product].name
	}

	return (

		<>

		{pageLoad ? (
			<PageLoad />
		) : (
			<div className="m-6 xl:m-10">
				<Navigation />

				{
					inrDiv ? (
						<div id="inrDiv" className="my-20 flex flex-col items-center justify-center mx-auto lg:mt-20">
							<div className="md:bg-neutral-900 w-full lg:w-1/2 2xl:w-1/3 px-4 lg:px-10 lg:py-10 md:rounded-lg">

								<div className="flex items-center">
									<p className="mr-2 text-2xl font-semibold text-slate-300">Invest</p>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-green-600">
			  							<path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</div>

								<div className="mt-10 flex items-center">
									<img src={products[props.product].img} alt="GLP Logo" className="w-10 h-10 mr-2" />
									<div className="flex flex-col">
										<p className="text-lg text-slate-300 font-medium">{products[props.product].name}</p>
										<p className="text-xs text-slate-400">{products[props.product].msg}</p>
									</div>
								</div>
								
								<p className="mt-6 text-xl font-medium text-slate-300">Enter INR Amount</p>

								<div className="mt-6 flex items-center">
									<input type="text" inputMode="numeric" pattern="[0-9]*" value={amount} onChange={(e) => fetchQuote(e.target.value)} onKeyPress={handleKeypress} className="pl-0 w-full text-2xl font-semibold tracking-wide border-none focus:ring-0 bg-neutral-950 md:bg-neutral-900 text-slate-100" placeholder="25000" />
									<p className="ml-auto text-xl font-medium text-slate-400">INR</p>
								</div>

								<p className="text-xs text-slate-400">Min. {MIN_INVEST} INR</p>

								<p className="mt-6 text-md text-slate-300">You will receive approx</p>

									{
										quote ? (
											<div className="mt-4 mb-10 grid grid-cols-2 text-sm text-slate-300 gap-2">
											
												<p className="font-semibold">{products[props.product].name} Quote</p>
												<p className="text-yellow-500 justify-self-end">~{parseFloat(quote.data.quote).toFixed(3)} Tokens</p>

												<p className="font-semibold">{products[props.product].name} Price</p>
												<p className="text-green-500 justify-self-end">~{parseFloat(quote.data.price).toFixed(3)} USD</p>

												<p className="font-semibold">{products[props.product].input_token} Price</p>
												<p className="text-green-500 justify-self-end">~{parseFloat(quote.data.rate).toFixed(3)} INR</p>
											</div>
										) : (
											<div className="mt-4 mb-10 animate-pulse grid grid-cols-2 gap-2">
												<div className="w-full h-4 rounded-lg bg-neutral-800"></div>
												<div className="w-1/2 h-4 rounded-lg bg-neutral-800 justify-self-end"></div>

												<div className="w-full h-4 rounded-lg bg-neutral-800"></div>
												<div className="w-1/2 h-4 rounded-lg bg-neutral-800 justify-self-end"></div>

												<div className="w-full h-4 rounded-lg bg-neutral-800"></div>
												<div className="w-1/2 h-4 rounded-lg bg-neutral-800 justify-self-end"></div>

											</div>
										)
									}

								<div className="sm:hidden mx-10 mb-10 fixed inset-x-0 bottom-0">

									{err && <p className="mt-4 mb-2 text-sm text-yellow-400">{err}</p>}

									{
										loading ? (
											<Loader />
										) : (
											<button onClick={createOrder} className="text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500 disabled:opacity-50" disabled={btnState}>Continue</button>
										)
									}
								</div>

								<div className="hidden sm:block">
									{err && <p className="mt-4 mb-2 text-sm text-yellow-400">{err}</p>}

									{
										loading ? (
											<Loader />
										) : (
											<button onClick={createOrder} className="text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500 disabled:opacity-50" disabled={btnState}>Continue</button>
										)
									}
								</div>

							</div>
					    </div>
					) : (
						// <div id="utrDiv" className="my-20 flex flex-col items-center justify-center mx-auto lg:mt-20">
						// 	<div className="md:bg-neutral-900 w-full lg:w-1/2 2xl:w-1/3 px-4 lg:px-10 lg:py-10 md:rounded-lg">

						// 		<div className="flex items-center">
						// 			<p className="mr-2 text-2xl font-semibold text-slate-300">Payment</p>
						// 			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-green-600">
			  			// 				<path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
						// 			</svg>
						// 		</div>
						// 		<p className="mt-2 text-sm text-slate-300">Make payment of exact <span className="font-extrabold">{amount} INR</span> using IMPS mode (Bank details mentioned below).</p>

						// 		<p className="mt-4 text-sm text-slate-300"><span className="font-extrabold">Note: </span> Update <span className="font-extrabold">12 digit transaction UTR</span> by clicking the button below or under pending transactions in transaction history page.</p>

						// 		{/* <div className="mt-8 p-4 bg-neutral-800 rounded-xl space-y-4">
						// 			<p className="text-lg font-semibold text-slate-300">UPI</p>
						// 			<p className="text-lg font-bold text-slate-300">onmeta@equitas</p>
						// 		</div> */}

						// 		<div className="mt-8 p-4 bg-neutral-800 rounded-xl">
						// 			<p className="text-lg font-semibold text-slate-300">IMPS Bank Transfer</p>
						// 			<p className="mt-2 text-xs xl:text-sm font-light text-slate-300">Transfer only from your bank app. DO NOT Transfer using any UPI app.</p>

						// 			<div className="mt-8 grid grid-cols-2 gap-2 text-xs">
						// 				<p className="font-medium text-slate-300">Account Name</p>
						// 				<p className="font-bold text-slate-300">Abhibha Technologies Private Limited <svg onClick={() => navigator.clipboard.writeText('Abhibha Technologies Private Limited')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
	  					// 						<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
						// 					</svg></p>
											
										
						// 				<p className="font-medium text-slate-300">IFSC</p>
						// 				<p className="font-bold text-slate-300">HDFC0000004 <svg onClick={() => navigator.clipboard.writeText('HDFC0000004')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
	  					// 						<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
						// 					</svg></p>

						// 				<p className="font-medium text-slate-300">Account Type</p>
						// 				<p className="font-bold text-slate-300">Current <svg onClick={() => navigator.clipboard.writeText('Current')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
	  					// 						<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
						// 					</svg></p>


						// 			</div>
						// 		</div>

						// 		{/*
						// 		<div className="mt-6 w-full border border-slate-300"></div>

						// 		<p className="mt-8 text-xl font-medium text-slate-300">Update UTR</p>

						// 		<div className="mt-4 flex items-center">
						// 			<input type="text" className="pl-0 w-full text-2xl font-semibold tracking-wide border-none focus:ring-0 bg-black md:bg-neutral-900 text-slate-400" placeholder="123450000000" />
						// 		</div>

						// 		<p className="text-xs text-slate-400">UPI: 12 Digits</p>
						// 		<p className="text-xs text-slate-400">IMPS: 12 Digits</p>
								
						// 		<p className="mt-8 text-xs text-yellow-400 text-center">INR transactions can take upto 48 hours to process</p>
						// 		<button className="mt-2 text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500/75">Update UTR</button>
						// 		*/}
								
						// 	</div>

						// 	<div className="sm:hidden mx-10 mb-10 fixed inset-x-0 bottom-0">
						// 		{err && <p className="mb-2 text-xs text-orange-400">{err}. Try again or <a href="#" className="text-red-500">contact us.</a></p>}

						// 		<button onClick={update_utr} className="mt-28 text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Update UTR</button>
									
						// 	</div>

						// 	<div className="hidden sm:block">
						// 		{err && <p className="mb-2 text-xs text-orange-400">{err}. Try again or <a href="#" className="text-red-500">contact us.</a></p>}

						// 		<button onClick={update_utr} className="text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Update UTR</button>
						// 	</div>
					    // </div>
						<div id="utrDiv" className="my-20 flex flex-col items-center justify-center mx-auto lg:mt-20">
							<div className="md:bg-neutral-900 w-full lg:w-1/2 2xl:w-1/3 px-4 lg:px-10 lg:py-10 md:rounded-lg">

								<div className="flex items-center">
									<p className="mr-2 text-2xl font-semibold text-slate-300">Payment</p>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-green-600">
			  							<path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</div>

								<div className="flex flex-col items-center">
									<div className="mt-10 w-full lg:w-3/4 flex items-center bg-neutral-900 lg:bg-neutral-800 rounded-xl">
										{ tab === 1 ? (
											<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-slate-300 rounded-lg">
												<p className="text-sm font-bold text-neutral-900">STEP 1</p>
											</div>
										) : (
											<div onClick={() => setTab(1)} className="cursor-pointer py-3 w-1/3 flex items-center justify-center space-x-2 rounded-lg">
												<p className="text-sm font-bold text-slate-300">STEP 1</p>
											</div>
										)}

										{ tab === 2 ? (
											<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-slate-300 rounded-lg">
												<p className="text-sm font-bold text-neutral-900">STEP 2</p>
											</div>
										) : (
											<div onClick={() => setTab(2)} className="cursor-pointer py-3 w-1/3 flex items-center justify-center space-x-2 rounded-lg">
												<p className="text-sm font-bold text-slate-300">STEP 2</p>
											</div>
										)}

										{ tab === 3 ? (
											<div className="py-3 w-1/3 flex items-center justify-center space-x-2 bg-slate-300 rounded-lg">
												<p className="text-sm font-bold text-neutral-900">STEP 3</p>
											</div>
										) : (
											<div onClick={() => setTab(3)} className="cursor-pointer py-3 w-1/3 flex items-center justify-center space-x-2 rounded-lg">
												<p className="text-sm font-bold text-slate-300">STEP 3</p>
											</div>
										)}
										
									</div>
								</div>

								{ tab === 1 &&
									<div className="mt-8 p-4 bg-neutral-800 rounded-xl">
										
										<p className="text-lg font-bold text-slate-300">STEP 1</p>

										<p className="mt-3 text-md font-semibold text-slate-300">Add Beneficiary (IMPS Only)</p>
										<p className="mt-2 text-xs xl:text-sm font-light text-slate-300">Transfer only from your bank app. DO NOT Transfer using any UPI app.</p>

										<div className="mt-8 grid grid-cols-2 gap-2 text-xs">
											<p className="font-medium text-slate-300">Account Name</p>
											<p className="font-bold text-slate-300">Abhibha Technologies Private Limited <svg onClick={() => navigator.clipboard.writeText('Abhibha Technologies Private Limited')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
		  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
												</svg></p>

											<p className="font-medium text-slate-300">Account Number</p>
											<p className="font-bold text-slate-300">50200070275321 <svg onClick={() => navigator.clipboard.writeText('50200070275321')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
	  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
											</svg></p>
												
											
											<p className="font-medium text-slate-300">IFSC</p>
											<p className="font-bold text-slate-300">HDFC0000004 <svg onClick={() => navigator.clipboard.writeText('HDFC0000004')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
		  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
												</svg></p>

											<p className="font-medium text-slate-300">Account Type</p>
											<p className="font-bold text-slate-300">Current <svg onClick={() => navigator.clipboard.writeText('Current')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-4 h-4 text-slate-400">
		  											<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
												</svg></p>

										</div>
									</div>
								}

								{ tab === 2 &&
									<div className="mt-8 p-4 bg-neutral-800 rounded-xl">
										<p className="text-lg font-bold text-slate-300">STEP 2</p>

										<p className="mt-3 text-md text-slate-300">Make payment of exact <span className="font-extrabold">{amount} INR</span> using IMPS mode.</p>
									</div>
								}

								{ tab === 3 &&
									<div className="mt-8 mb-28 p-4 bg-neutral-800 rounded-xl">
										<p className="text-lg font-bold text-slate-300">STEP 3</p>

										<p className="mt-3 text-md font-semibold text-slate-300">Update UTR</p>

										<p className="mt-4 text-sm text-slate-300"><span className="font-extrabold">Note: </span> Update <span className="font-extrabold">12 digit transaction UTR</span> by clicking the button below or under pending transactions in transaction history page.</p>
										
									</div>
								}

								{ tab === 1 &&

									<>

									<div className="sm:hidden p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
										<button onClick={() => setTab(2)} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">NEXT</p>
										</button>
									</div>

									<div className="hidden sm:block mt-10">
										<button onClick={() => setTab(2)} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">NEXT</p>
										</button>
									</div>

									</>
								}

								{ tab === 2 &&

									<>

									<div className="sm:hidden p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
										<button onClick={() => setTab(3)} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">NEXT</p>
										</button>
									</div>

									<div className="hidden sm:block mt-10">
										<button onClick={() => setTab(3)} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">NEXT</p>
										</button>
									</div>

									</>
								}

								{ tab === 3 &&

									<>

									<div className="sm:hidden p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
										<button onClick={update_utr} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">UPDATE UTR</p>
										</button>
									</div>

									<div className="hidden sm:block mt-10">
										<button onClick={update_utr} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
											<p className="text-lg text-slate-100">UPDATE UTR</p>
										</button>
									</div>

									</>
								}						
								
							</div>

							{/*
								<div className="sm:hidden mx-10 mb-10 fixed inset-x-0 bottom-0">
									{err && <p className="mb-2 text-xs text-orange-400">{err}. Try again or <a href="#" className="text-red-500">contact us.</a></p>}

									<button onClick={update_utr} className="mt-28 text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Update UTR</button>
										
								</div>

								<div className="hidden sm:block">
									{err && <p className="mb-2 text-xs text-orange-400">{err}. Try again or <a href="#" className="text-red-500">contact us.</a></p>}

									<button onClick={update_utr} className="text-white py-2 px-6 rounded-lg text-sm drop-shadow-md w-full bg-red-500">Update UTR</button>
								</div>
							*/}
					    </div>
					)
				}

			</div>
		)}

		<Help />

		</>

	)
}	

export default Invest;