import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

// Components
import Navigation from '../components/navigation'
import PageLoad from '../components/page_load'
import Help from '../components/help'

import { API_HOST } from '../config_vars'

const Profile = () => {

	const navigate = useNavigate();

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [pageLoad, setPageLoad] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | PROFILE'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
		})

		fetch(API_HOST + '/profile', {
				headers: {
					'Authorization': localStorage.getItem('accessToken')
				}
			})
			.then(res => res.json())
			.then(
				(data) => {
					console.log(data)

					if(data.code === 200) {
						setName(data.data.name)
						setEmail(data.data.email)
					} else {
						setName('')
						setEmail('')
					}
				},
				(error) => {
					setName('')
					setEmail('')
				}
			)

	}, [])

	return (

		<>
			{ pageLoad ? (
				<PageLoad />
			) : (
				<>
					<Navigation />

					<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
						<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">

							<div className="mt-20 lg:mt-0 flex items-center justify-center">
								<div className="flex h-14 w-14 xl:h-20 xl:w-20 items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-yellow-500">
					    			{ name && <p className="text-2xl xl:text-4xl font-medium">{name[0]}</p>}
					  			</div>
							</div>

							<div className="mt-6">
								<p className="text-sm font-light text-slate-400">NAME</p>
								{ name ? (
									<p className="text-md font-medium text-slate-300">{name}</p>
								) : (
									<div className="animate-pulse w-1/2 h-4 bg-neutral-700 rounded-lg"></div>
								)}

								<p className="mt-4 text-sm font-light text-slate-400">EMAIL</p>
								{ email ? (
									<p className="text-md font-medium text-slate-300">{email}</p>
								) : (
									<div className="animate-pulse w-1/2 h-4 bg-neutral-700 rounded-lg"></div>
								)}
								<p className="mt-4 text-sm font-light text-slate-400">PHONE</p>
								<p className="text-md font-medium text-slate-300">NA</p>

							</div>

							<div className="my-6 w-full border-t border-red-500"></div>

							<div className="mb-4 flex items-center">
								<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">Account Status</p>
								<p className="text-sm xl:text-md font-medium text-green-300">Active</p>
							</div>

							<div className="mb-4 flex items-center">
								<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">KYC</p>
								<p className="text-sm xl:text-md font-medium text-green-300">Verified</p>
							</div>

							<div className="mb-4 flex items-center">
								<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">Bank Details</p>
								<p className="text-sm xl:text-md font-medium text-green-300">Added</p>
							</div>

							<div onClick={() => navigate('/logout')} className="my-10 px-4 py-3 w-full bg-neutral-800 rounded-xl">
								<div className="cursor-pointer flex items-center">
									<div className="w-10 h-10 flex flex-col items-center justify-center bg-neutral-700 rounded-lg">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-300">
			  								<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
										</svg>
									</div>
									<p className="ml-4 text-md text-slate-300">Logout</p>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
										<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
									</svg>
								</div>
							</div>
							
						</div>
					</div>

					{/*<div className="px-6 my-24">
					<div className="w-full lg:w-1/3 p-6 bg-neutral-900 rounded-2xl">

						<div className="flex items-center justify-center">

							<div className="flex h-14 w-14 xl:h-20 xl:w-20 items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-yellow-500">
				    			<p className="text-2xl xl:text-4xl font-medium">S</p>
				  			</div>

						</div>

						<div className="mt-6">
							<p className="text-sm font-light text-slate-400">NAME</p>
							<p className="text-md font-medium text-slate-300">Suraj Kumar Jana</p>

							<p className="mt-4 text-sm font-light text-slate-400">EMAIL</p>
							<p className="text-md font-medium text-slate-300">surajjana2@gmail.com</p>

							<p className="mt-4 text-sm font-light text-slate-400">PHONE</p>
							<p className="text-md font-medium text-slate-300">NA</p>

						</div>

						<div className="my-6 w-full border-t border-red-500"></div>

						<div className="mb-4 flex items-center">
							<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">Account Status</p>
							<p className="text-sm xl:text-md font-medium text-green-300">Active</p>
						</div>

						<div className="mb-4 flex items-center">
							<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">KYC</p>
							<p className="text-sm xl:text-md font-medium text-green-300">Verified</p>
						</div>

						<div className="mb-4 flex items-center">
							<p className="text-sm xl:text-md font-medium text-slate-300 mr-auto">Bank Details</p>
							<p className="text-sm xl:text-md font-medium text-green-300">Added</p>
						</div>

					</div>
					</div>*/}
				</>
			)}

			<Help />
		</>
	)
}

export default Profile;