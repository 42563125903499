import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_HOST } from '../config_vars'

const Navigation = (props) => {
	
	const navigate = useNavigate();

	const [name, setName] = useState('')
	const [fname, setFName] = useState('')

	useEffect(() => {

		fetch(API_HOST + '/profile', {
				headers: {
					'Authorization': localStorage.getItem('accessToken')
				}
			})
			.then(res => res.json())
			.then(
				(data) => {
					// console.log(data)

					if(data.code === 200) {
						setName(data.data.name)

						let n = (data.data.name).split(' ')[0].toLowerCase()

						// n[0] = n[0].toUpperCase()
						n = n.replace(n[0], n[0].toUpperCase())

						setFName(n)

					} else {
						setName('')
					}
				},
				(error) => {
					setName('')
				}
			)

	}, [])

	// console.log(navigate(-1))

	if (props.nav === 'main') {
		return (
			<div className="p-6 flex items-center fixed inset-x-0 top-0 left-0 bg-neutral-950">
	 			<div className="flex items-center space-x-2">
	 				<div onClick={() => navigate('/profile')} className="cursor-pointer w-9 h-9 flex flex-col items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-yellow-500">
	 					{ name && <p className="text-md text-neutral-900">{name[0]}</p>}
	 				</div>
	 				<div className="flex flex-col">
	 					{ fname ? (
	 						<p className="text-sm text-slate-300">Hello {fname}</p>
	 					) : (
	 						<p className="text-sm text-slate-300">Hello</p>
	 					)}
	 					<p className="text-xs font-light text-slate-400">Welcome, back</p>
	 				</div>
	 			</div>

	 			<div className="ml-auto">
	 				<div className="flex items-center space-x-3">
	 					<div className="hidden px-3 py-1 border border-slate-400 rounded-xl">
	 						<p className="text-sm text-slate-400">Refer</p>
	 					</div>

	 					{/*<div onClick={() => window.location.href = '/transactions'} className="cursor-pointer p-1 rounded-lg bg-gradient-to-r from-blue-500 to-green-500">
		 					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-900">
	  							<path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
							</svg>
						</div>*/}

						{/*<div onClick={() => window.location.href = '/transactions'} className="cursor-pointer p-1.5 rounded-lg bg-slate-400">
		 					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-neutral-900">
								<path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
							</svg>
						</div>*/}

						<div onClick={() => navigate('/transactions')} className="px-3 py-2 flex items-center cursor-pointer rounded-lg bg-gradient-to-r from-blue-500 to-green-500">
		 					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-900">
	  							<path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
							</svg>
							<p className="text-xs text-slate-900 font-medium">Txns</p>
						</div>
	 					{/*<svg onClick={() => window.location.href = '/menu'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-7 h-7 text-slate-300">
	 							<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
	 					</svg>*/}
	 					{/*<svg onClick={() => window.location.href = '/menu'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-8 h-8 text-slate-300">
  							<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
						</svg>*/}
	 				</div>
	 			</div>
	 		</div>
	 	)
	} else if (props.nav === 'transactions') {
		return (
			<div className="p-6 flex items-center fixed inset-x-0 top-0 left-0 bg-neutral-950">
	 			<svg onClick={() => navigate('/transactions')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="cursor-pointer ml-auto w-8 h-8 text-slate-300">
  	 				<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
	 			</svg>
	 		</div>
		)
	} else {
		return (
			<div className="p-6 flex items-center fixed inset-x-0 top-0 left-0 bg-neutral-950">
	 			<svg onClick={() => navigate('/home')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="cursor-pointer ml-auto w-8 h-8 text-slate-300">
  	 				<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
	 			</svg>
	 		</div>
		)
	}

	// return (
	// 	<>
	// 	{ props.nav === 'main' ? (
	// 		<div className="p-6 flex items-center fixed inset-x-0 top-0 left-0 bg-neutral-950">
	// 			<div className="flex items-center space-x-2">
	// 				<div onClick={() => window.location.href = '/profile'} className="cursor-pointer w-9 h-9 flex flex-col items-center justify-center rounded-full bg-gradient-to-r from-red-500 to-yellow-500">
	// 					{ name && <p className="text-md text-neutral-900">{name[0]}</p>}
	// 				</div>
	// 				<div className="flex flex-col">
	// 					{ fname ? (
	// 						<p className="text-sm text-slate-300">Hello {fname}</p>
	// 					) : (
	// 						<p className="text-sm text-slate-300">Hello</p>
	// 					)}
	// 					<p className="text-xs font-light text-slate-400">Welcome, back</p>
	// 				</div>
	// 			</div>

	// 			<div className="ml-auto">
	// 				<div className="flex items-center space-x-4">
	// 					<div className="hidden px-3 py-1 border border-slate-400 rounded-xl">
	// 						<p className="text-sm text-slate-400">Refer</p>
	// 					</div>
	// 					<svg onClick={() => window.location.href = '/menu'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-7 h-7 text-slate-300">
	// 							<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
	// 					</svg>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	) : (
	// 		<div className="p-6 flex items-center fixed inset-x-0 top-0 left-0 bg-neutral-950">
	// 			{/*
	// 			<svg onClick={() => navigate(-1)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 text-slate-300">
	//   				<path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
	// 			</svg>
	// 			*/}
	// 			<svg onClick={() => window.location.href = '/home'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="cursor-pointer ml-auto w-8 h-8 text-slate-300">
  	// 				<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
	// 			</svg>
	// 		</div>
	// 	)}
	// 	</>
	// )
}

export default Navigation;