import { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../firebase-config';

// Components
import Navigation from '../components/navigation'
import HomeFooter from '../components/home/footer'
import Portfolio from '../components/home/portfolio'
import L15 from '../components/home/l15'
import Overview from '../components/home/overview'
import Composition from '../components/home/composition'
import PageLoad from '../components/page_load'
import Help from '../components/help'


const Home = () => {

	const [pageLoad, setPageLoad] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | HOME'

		onAuthStateChanged(auth, (user) => {
			
			if(user && user.emailVerified === true) {
					
				auth.currentUser.getIdToken(true)
				.then(function(refreshToken) {
					localStorage.setItem('accessToken', refreshToken)

					setPageLoad(false)
					
				}).catch(function(error) {
					// console.log(error)
					window.location.href = '/app'
				});
				// console.log(user)
				
			} else {
				console.log('No user')
				window.location.href = '/app'
			}
	})

	}, [])

	return (
		<>
		{ pageLoad ? (
			<PageLoad />
		) : (
			<>
				<Navigation nav="main" />

				<Portfolio />

				<L15 />

				<Overview />

				<Composition />

				<HomeFooter />

				<Help />
				
			</>
		)}
		
		</>
	)
}

export default Home;