import { useState } from 'react';

import { API_HOST } from '../../config_vars'

const Composition = () => {

	const [active, setActive] = useState(false)
	const [L15Index, setL15Index] = useState('')

	const click_handler = () => {
		if(active === true) {
			setActive(false)
		} else {

			fetch(API_HOST + '/l15/index', {
				// headers: {
				// 	'Authorization': localStorage.getItem('accessToken')
				// }
			})
			.then(res => res.json())
			.then(
				(data) => {
					if(data.code === 200) {
						setL15Index(data)
					} else {
						setL15Index('')
					}
				},
				(error) => {
					setL15Index('')
				}
			)

			setActive(true)
		}
	}

	return (
		<div className="px-6 mt-8 mb-40">
			{active ? (
				<div className="px-6 py-4 w-full bg-neutral-900 rounded-xl">
					<div onClick={click_handler} className="cursor-pointer flex items-center">
						<p className="text-lg text-slate-300">Composition</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
  							<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
						</svg>
					</div>

					{L15Index ? (
		
							
						L15Index.data.map(asset => {
							return (
								<div key={asset.symbol} className="my-6 grid grid-cols-8 gap-y-6">
						 			<img alt={asset.name} src={asset.img} className="col-span-2 w-8 h-8 self-center" />
						 			<div className="col-span-4 flex flex-col self-center">
						 				<p className="text-sm font-medium text-slate-300">{asset.name}</p>
						 				<p className="text-xs font-light text-slate-400">${asset.price.current}</p>
						 			</div>
						 			<p className="col-span-2 text-sm font-medium text-slate-300 self-center justify-self-end">{asset.holding_percentage}%</p>
								</div>
							)
						})
							
						
						
					) : (
						<div className="my-6 grid grid-cols-3 gap-y-6">
							<div className="animate-pulse w-8 h-8 rounded-full bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-full h-4 rounded-lg bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-8 h-8 rounded-lg bg-neutral-800 self-center justify-self-end"></div>

							<div className="animate-pulse w-8 h-8 rounded-full bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-full h-4 rounded-lg bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-8 h-8 rounded-lg bg-neutral-800 self-center justify-self-end"></div>

							<div className="animate-pulse w-8 h-8 rounded-full bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-full h-4 rounded-lg bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-8 h-8 rounded-lg bg-neutral-800 self-center justify-self-end"></div>

							<div className="animate-pulse w-8 h-8 rounded-full bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-full h-4 rounded-lg bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-8 h-8 rounded-lg bg-neutral-800 self-center justify-self-end"></div>

							<div className="animate-pulse w-8 h-8 rounded-full bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-full h-4 rounded-lg bg-neutral-800 self-center"></div>
							<div className="animate-pulse w-8 h-8 rounded-lg bg-neutral-800 self-center justify-self-end"></div>
						</div>
					)}


				</div>
			) : (
				<button className="px-6 py-4 w-full bg-neutral-900 rounded-xl">
					<div onClick={click_handler} className="cursor-pointer flex items-center">
						<p className="text-lg text-slate-300">Composition</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
	  						<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
						</svg>
					</div>
				</button>
			)}
			
		</div>
	)
}

export default Composition;