import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { API_HOST } from '../../config_vars';

const KycBank = () => {

	const [acc, setAcc] = useState('');
	const [ifsc, setIfsc] = useState('');
	const [err, setErr] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		document.title = 'LASER.MONEY | KYC | BANK'
	}, [])

	const add_bank = () => {
		
		setErr('')
		setLoading(true)

		fetch(API_HOST + '/kyc/bank', {
			method: 'POST',
			body: JSON.stringify({
				acc_num: acc,
				ifsc: ifsc
			}),
			headers: {
				'Accept': 'application/json',
      			'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('accessToken')
			}
		})
		.then(res => res.json())
		.then(
		    (result) => {

		    	if(result.code === 200) {
		    		window.location.href = '/home'
		    	} else {
		    		setLoading(false)
		    		setErr('Error adding bank account.')
		    	}
		    },
		    (error) => {
				setLoading(false)
		    	setErr('Error adding bank account.')
		    }
		)
	}

	const handleKeypress = e => {
		if(e.key === 'Enter') {
			add_bank()
			e.target.blur()
		}
	}

	return (
		<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
			<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">
				
				<div className="mt-10 lg:mt-0 w-20 h-20 flex flex-col items-center justify-center bg-green-200 rounded-xl">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 text-green-800">
  						<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
					</svg>
				</div>

				<p className="mt-10 text-3xl text-slate-300">Enter Bank Details</p>
				{/*<p className="mt-6 lg:mb-20 text-md font-light text-slate-400">Please complete your KYC before investing. Keep your <span className="font-bold">PAN</span> and <span className="font-bold">Aadhaar</span> number handy.</p>*/}

				<div className="mt-6 flex flex-col">
					<p className="text-sm font-light text-slate-400">ACCOUNT NUMBER</p>
					<input inputMode="numeric" pattern="[0-9]*" value={acc} onChange={(e) => setAcc(e.target.value)} className="mt-2 px-4 py-3 text-2xl font-medium text-slate-100 bg-neutral-800 rounded-xl focus:ring-0" placeholder="1234XXXXXXXX" />
				</div>

				<div className="mt-6 flex flex-col">
					<p className="text-sm font-light text-slate-400">IFSC</p>
					<input value={ifsc} onChange={(e) => setIfsc(e.target.value)} onKeyPress={handleKeypress} className="uppercase mt-2 px-4 py-3 text-2xl font-medium text-slate-100 bg-neutral-800 rounded-xl focus:ring-0" maxLength="11" placeholder="ICICXXXXXXX" />
				</div>

				<p className="mt-4 lg:mb-20 text-sm font-light text-slate-300">NOTE: The name of the holder of this bank account should match exactly with the name on the PAN card and Aadhar card.</p>


				<div className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
					{err && <p className="mb-4 text-sm text-orange-400">{err} Try again or <Link to="https://docs.laser.money/reach-us" target="_blank" className="text-red-500">contact us.</Link></p>}

					{ loading ? (
							<button className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
								<div className="flex items-center">
									<div className="flex flex-col items-center">
		                				<div className="w-6 h-6 rounded-full animate-spin border-2 border-solid border-slate-100 border-t-transparent"></div>
		            				</div>
								</div>
							</button>
						) : (
							<button onClick={add_bank} className="w-full px-6 py-3 flex flex-col items-center bg-red-500 rounded-xl">
								<p className="text-lg text-slate-100">UPDATE BANK</p>
							</button>
						)
					}

				</div>

			</div>
		</div>
	)
}

export default KycBank;