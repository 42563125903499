import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Landing from './pages/landing'

import Login from './pages/login';
import Home from './pages/home';
import Profile from './pages/profile';
import Menu from './pages/menu';
import Help from './pages/help';

import KycWelcome from './pages/kyc/welcome'
import KycPAN from './pages/kyc/pan'
import KycAadhaar from './pages/kyc/aadhaar'
import KycBank from './pages/kyc/bank'

import Invest from './pages/invest'
import Transactions from './pages/transactions'
import UpdateUTR from './pages/update_utr'
import Withdraw from './pages/withdraw'

import Logout from './pages/logout'

import Test from './pages/test'
import Chart from './pages/chart'

import NoPage from './pages/no_page';


const App = () => {
	return (
		<>

			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Landing />} />

					<Route path='/app' element={<Login />} />
					<Route path='/home' element={<Home />} />
					<Route path='/profile' element={<Profile />} />
					<Route path='/menu' element={<Menu />} />
					<Route path='/help' element={<Help />} />

					{/* KYC */}
					<Route path='/kyc-welcome' element={<KycWelcome />} />
					<Route path='/kyc-pan' element={<KycPAN />} />
					<Route path='/kyc-aadhaar' element={<KycAadhaar />} />
					<Route path='/kyc-bank' element={<KycBank />} />

					<Route path='/invest' element={<Invest product='l15' />} />
					<Route path='/transactions' element={<Transactions />} />
					<Route path='/update-utr' element={<UpdateUTR />} />
					<Route path='/withdraw' element={<Withdraw />} />

					<Route path='/logout' element={<Logout />} />

					<Route path='/test' element={<Test />} />
					<Route path='/chart' element={<Chart />} />
					
					<Route path='*' element={<NoPage />} />

				</Routes>
			</BrowserRouter>

		</>
	);
}

export default App;