import { useNavigate } from 'react-router-dom';

const HomeFooter = () => {
	
	const navigate = useNavigate();

	return (
		<>
			<div className="p-6 flex items-center fixed inset-x-0 bottom-0 bg-neutral-950">
				<div className="w-full flex items-center space-x-4">
					<button onClick={() => navigate('/withdraw')} className="w-1/2 py-2 border border-slate-400 rounded-xl text-lg text-slate-400">Withdraw</button>
					<button onClick={() => navigate('/invest')} className="w-1/2 py-2 bg-red-500 rounded-xl text-lg text-slate-100">Invest</button>
				</div>

				
			</div>
		</>
	)
}

export default HomeFooter;