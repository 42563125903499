import { useEffect, useState } from 'react';
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, onAuthStateChanged, signOut } from 'firebase/auth';

import { app, auth, sign_out } from '../firebase-config';
import { API_HOST } from '../config_vars';

const Login = () => {

	const [login, setLogin] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		document.title = 'LASER.MONEY | LOGIN';

		onAuthStateChanged(auth, (user) => {
			
			setLogin(false)
			setLoading(true)

			if(user && user.emailVerified === true) {
					
				console.log(user)

				localStorage.setItem('accessToken', user.accessToken);
				localStorage.setItem('uid', user.uid);

				fetch(API_HOST + '/checkAuth', {
					headers: {
						'Authorization': localStorage.getItem('accessToken')
					}
				})
				.then(res => res.json())
				.then((data) => {
					if(data.code === 200) {
						window.location.href = data.path
						// console.log(data.path)
					} else {
						sign_out()
					}
				})


				setLogin(false)
				setLoading(true)
				
			} else {
				console.log('No user')
				setLogin(true)
				setLoading(false)
			}
	})


	}, []);

	const gsign = () => {

		const provider = new GoogleAuthProvider();

		setLogin(false)
		setLoading(true)

		// signInWithRedirect(auth, provider);
		signInWithPopup(auth, provider);
	}

	return (
		<div className="flex flex-col items-center justify-center mx-auto lg:h-screen lg:mt-0">
			<div className="p-10 w-full lg:w-1/3 2xl:w-1/4 flex flex-col lg:bg-neutral-900 lg:rounded-xl">
				
				<div className="mt-10 lg:mt-0 flex items-center space-x-4">
					<div className="w-12 h-12 bg-red-500 rounded-xl"></div>
					<div className="flex flex-col">
						<p className="text-lg font-medium text-slate-300">L15 Index</p>
						<p className="text-sm font-light text-slate-300">Top 15 cryptocurrency benchmark</p>
					</div>
				</div>

				<p className="mt-10 lg:mt-10 text-4xl text-slate-300">India's Only Web3 Index Token</p>
				<p className="mt-10 lg:mb-20 text-md font-light text-slate-400">Get complete market exposure by holding just one token.</p>

				{login &&
					<div onClick={gsign} className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
						<button className="w-full px-6 py-3 flex flex-col items-center bg-slate-300 rounded-xl">
							<div className="flex items-center space-x-4">
								<img src="img/google.png" className="w-8 h-8" />
								<p className="text-lg text-neutral-900">Continue with Google</p>
							</div>
						</button>
					</div>
				}

				{loading &&
					<div className="p-10 lg:p-0 fixed lg:static inset-x-0 bottom-0 bg-neutral-950 lg:bg-neutral-900">
						<button className="w-full px-6 py-3 flex flex-col items-center bg-slate-300 rounded-xl">
							<div className="flex items-center space-x-4">
								<div className="flex flex-col items-center">
	                				<div className="w-8 h-8 rounded-full animate-spin border-2 border-solid border-neutral-900 border-t-transparent"></div>
	            				</div>
								<p className="text-lg text-neutral-900">Checking authentication</p>
							</div>
						</button>
					</div>
				}

			</div>
		</div>
	);
};

export default Login;