import { useState } from 'react';

const Overview = () => {

	const [active, setActive] = useState(false)

	const click_handler = () => {
		if(active === true) {
			setActive(false)
		} else {
			setActive(true)
		}
	}

	return (
		<div className="px-6 mt-8">
			{active ? (
				<div className="p-6 w-full bg-neutral-900 rounded-xl">
					<div onClick={click_handler} className="cursor-pointer flex items-center">
						<p className="text-lg text-slate-300">Overview</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
  							<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
						</svg>
					</div>

					<p className="mt-6 text-sm font-light text-slate-400">STRATEGY</p>
					<p className="mt-1 text-sm font-light text-slate-300">Passive investment strategy focused on providing broad based exposure to the digital asset market.</p>

					<div className="mt-10 grid grid-cols-2 gap-y-4">
						<p className="text-md font-light text-slate-400">Token</p>
						<p className="text-md text-right text-slate-300 justify-self-end">L15</p>

						<p className="text-md font-light text-slate-400">Network</p>
						<p className="text-md text-right text-slate-300 justify-self-end">Multi-chain</p>

						<p className="text-md font-light text-slate-400">Holdings</p>
						<p className="text-md text-right text-slate-300 justify-self-end">Top 15 mcap tokens</p>

						<p className="text-md font-light text-slate-400">Rebalancing</p>
						<p className="text-md text-right text-slate-300 justify-self-end">Monthly</p>

						<p className="text-md font-light text-slate-400">Annual Fee</p>
						<p className="text-md text-right text-slate-300 justify-self-end">0.96%</p>

						<p className="text-md font-light text-slate-400">Minimum Deposit</p>
						<p className="text-md text-right text-slate-300 justify-self-end">20,000 INR</p>

						<p className="text-md font-light text-slate-400">Withdrawal</p>
						<p className="text-md text-right text-slate-300 justify-self-end">T+1</p>
					</div>
				</div>
			) : (
				<button className="px-6 py-4 w-full bg-neutral-900 rounded-xl">
					<div onClick={click_handler} className="cursor-pointer flex items-center">
						<p className="text-lg text-slate-300">Overview</p>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-auto w-6 h-6 text-slate-300">
	  						<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
						</svg>
					</div>
				</button>
			)}
			
		</div>
	)
}

export default Overview;