import { useEffect } from 'react';

const NoPage = () => {
	useEffect(() => {
		document.title = 'LASER.MONEY | SNAP | 404'
	}, [])

	return (
		<div className="flex flex-col items-center justify-center mx-auto lg:h-screen mt-20 lg:mt-0">
			<div className="p-10 w-full lg:w-1/3 flex flex-col items-center lg:bg-neutral-900 lg:rounded-xl">
				<img alt="404" src="img/404.jpg" className="w-full rounded-xl" />
				<p className="mt-6 lg:mt-10 text-4xl lg:text-5xl font-semibold text-slate-300">Span :(</p>
				<p className="mt-6 lg:mt-10 text-lg lg:text-xl text-slate-400">You reached an unkown path</p>
			</div>

		</div>
	)
}

export default NoPage;